import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { fetchWithSession } from '../../redux/session';

const EditCourse = () => {
  const { resourceId, courseId } = useParams();
  const api_url = process.env.REACT_APP_BASE_API_URL;
  const [loading, setLoading] = useState(false);
  const [coursetitle, setCourseTitle] = useState('');
  const [coursedesc, setCourseDesc] = useState('');

  const navigate = useNavigate();

  const editCourse = async () => {
    setLoading(true);
    const data = {
      coursetitle,
      coursedesc,
    };
    try {
      const response = await fetchWithSession(`${api_url}/api/resource/${resourceId}/course/${courseId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: data,
      });

      if (response.status === 200) {
        alert('Course updated successfully');
        setLoading(false);
        navigate(-1);
      } else {
        setLoading(false);
        alert('Failed to update course');
      }
    } catch (error) {
      console.error('Error updating course:', error);
      setLoading(false);
      alert(`Error updating course: ${error?.response?.data?.error}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-[4%]">
      <div className="flex justify-between items-center gap-2 mb-8">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="text-[28px] text-[#121212] font-[400] workSans">
          &larr;
        </button>
        <h2 className="text-[28px] text-[#121212] font-[700] workSans">Edit course</h2>
      </div>

      <div className="grid grid-cols-3 gap-6 mb-6">
        <div className="col-span-2">
          <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2">course name</label>
          <input
            type="text"
            placeholder="Type course name here"
            value={coursetitle}
            onChange={(e) => setCourseTitle(e.target.value)}
            required
            className="w-full px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[8px] focus:outline-none focus:ring-2 focus:ring-indigo-600 text-[#BFBBBB] text-[16px] font-[400] workSans"
          />
        </div>
      </div>

      <div className="mb-6">
        <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2">Description</label>
        <textarea
          value={coursedesc}
          onChange={(e) => setCourseDesc(e.target.value)}
          required
          className="h-32 w-full px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[8px] focus:outline-none focus:ring-2 focus:ring-indigo-600 text-[#BFBBBB] text-[16px] font-[400] workSans"
          placeholder="Enter description here"
        />
      </div>
      <div className="flex items-center justify-center gap-4 mt-4">
        <button
          type="button"
          onClick={editCourse}
          className="linearGradient my-4 rounded-[32px] text-[14px] font-[500] workSans text-[#fff]">
          {loading ? 'Updating...' : 'Update Course'}
        </button>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="border-[2px] py-[11px] px-[35px] border-[#7C067D] my-4 rounded-[32px] text-[14px] font-[500] workSans text-[#7C067D]"
        >Cancel
        </button>
      </div>
    </div>
  )
}

export default EditCourse;
