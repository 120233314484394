import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchResources } from '../../redux/resources/resources';
import { useParams, useNavigate } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { fetchWithSession } from '../../redux/session';

const ManageCourse = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const api_url = process.env.REACT_APP_BASE_API_URL;
  const { resourceId, courseId } = useParams();
  const navigate = useNavigate();
  const { resources, loadingResources, resourcesError } = useSelector((state) => state.getResources);
  const resource = resources?.find((resource) => resource?.schooltitle.toLowerCase().replace(/\s+/g, '-') === resourceId);
  const course = resource?.schoolcourses.find((course) => course?.coursetitle.toLowerCase().replace(/\s+/g, '-') === courseId)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchResources());
  }, [dispatch]);

  useEffect(() => {
    if (resources?.length > 0) {
      if (!resourceId || !resources?.some(resource => resource?.schooltitle?.toLowerCase().replace(/\s+/g, '-') === resourceId) || !resource?.schoolcourses.some(course => course?.coursetitle.toLowerCase().replace(/\s+/g, '-') === courseId)) {
        navigate("/resources");
      }
    }
  }, [resourceId, resources, navigate]);
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleDelete = async (resourceID, courseId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this course?');
    if (!confirmDelete) return;
    try {
      const response = await fetchWithSession(`${api_url}/api/resource/${resourceID}/course/${courseId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        alert('Course deleted successfully');
        navigate(`/resources/school/${resourceId}`);
      } else {
        alert('Failed to delete course');
      }
    } catch (error) {
      console.error('Error deleting course:', error);
      alert('Error deleting course');
    }
  };

  return (
    <div className="p-[4%]">
      {loadingResources && <p className="text-[#616161] text-[16px] font-[500] workSans">Loading modules...</p>}
      {resourcesError && <p className="text-[#616161] text-[16px] font-[500] workSans">Error loading modules</p>}
      {resource && (
        <div>
          <h1 className="text-[#011114] text-[40px] font-[400] baskerville text-center py-4">{course?.coursetitle}</h1>
          <img src={resource?.schoolimage} alt="course_img" className="w-full md:w-[1280px] md:h-[300px] rounded-[16px]" />
          <div className="flex items-center gap-4 py-[2%]">
            <p className="text-[#616161] workSans text-[16px] font-[500]">{resource?.schooldifficulty}</p>
            <p className="text-[#616161] workSans text-[16px] font-[500]">|</p>
            <p className="text-[#616161] workSans text-[16px] font-[500]">{course?.coursemodules?.length} module{course?.coursemodules?.length > 1 ? 's' : ''}</p>
            <p className="text-[#616161] workSans text-[16px] font-[500]">|</p>
            <p className="text-[#616161] workSans text-[12px] bg-[#E20BE333] rounded-[8px] px-[8px] py-[2px] font-[500]">{resource?.schoolcategory}</p>
          </div>
          <p className="font-[600] text-[32px] mb-4 workSans text-[#011114]">DESCRIPTION</p>
          <p className="text-[#011114] text-[16px] font-[400] workSans">
            {course?.coursedesc}
          </p>
          <div className="flex justify-between items-center my-4 gap-4">
            <p className="font-[600] text-[32px] workSans text-[#011114]">MODULES</p>
            <button
              type="button"
              className="linearGradient rounded-[32px] text-[14px] font-[500] workSans text-[#fff]"
              onClick={() => navigate(`/create_module/${course._id}`)}
            >
              Add modules
            </button>
          </div>
          {course?.coursemodules.map((item, index) => (
            <div key={index} className="mb-2 bg-[#5900641A] p-[40px] rounded-[16px]">
              <div
                className="cursor-pointer flex justify-between items-center"
                onClick={() => toggleAccordion(index)}
              >
                <div>
                  <h2 className="text-[#011114] text[24px] font-[700] workSans">{item.moduletitle}</h2>
                  <p className="text-[#333333] text-[16px] font-[400] workSans">{item.moduledesc.split(" ").slice(0, 7).join(" ")}</p>
                </div>
                <div className="flex items-center gap-8">
                  <button
                    type="button"
                    onClick={() => navigate(`/resources/school/${resource.schooltitle.toLowerCase().replace(/\s+/g, '-')}/course/${course.coursetitle.toLowerCase().replace(/\s+/g, '-')}/module/${item.moduletitle.toLowerCase().replace(/\s+/g, '-')}`)}
                    className={`${activeIndex === index ? "hidden" : "linearGradient hidden md:block rounded-[32px] text-[14px] font-[500] workSans text-[#fff]"}`}
                  >
                    Manage
                  </button>
                  <div className="header-icon cursor-pointer text-black">
                    {activeIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                  </div>
                </div>
              </div>
              {activeIndex === index && (
                <div className="">
                  <div className="my-8 border-[#BFBBBB] border-[1px]"></div>
                  <p className="text-[#333333] text-[16px] font-[400] workSans">
                    {item.moduledesc}
                  </p>
                  <button
                    type="button"
                    onClick={() => navigate(`/resources/school/${resource.schooltitle.toLowerCase().replace(/\s+/g, '-')}/course/${course.coursetitle.toLowerCase().replace(/\s+/g, '-')}/module/${item.moduletitle.toLowerCase().replace(/\s+/g, '-')}`)}
                    className="linearGradient mt-4 rounded-[32px] text-[14px] font-[500] workSans text-[#fff]"
                  >
                    Manage
                  </button>
                </div>
              )}
            </div>
          ))}
          <hr />
          <div className="flex items-center justify-center gap-4 mt-4">
            <button
              type="button"
              className="linearGradient my-4 rounded-[32px] text-[14px] font-[500] workSans text-[#fff]"
              onClick={() => navigate(`/edit_course/${resource._id}/${course._id}`)}
            >Edit course
            </button>
            <button
              type="button"
              onClick={() => handleDelete(resource._id, course._id)}
              className="border-[2px] py-[11px] px-[35px] border-[#7C067D] my-4 rounded-[32px] text-[14px] font-[500] workSans text-[#7C067D]"
            >Delete course
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default ManageCourse;
