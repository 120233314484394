import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEvents } from '../redux/event/events';
import { getToken } from '../redux/auth/signin';
import { auth, signInAnonymously } from '../firebase';
import { getUserDetails } from '../redux/auth/signin';
import Sidebar from '../components/Sidebar';
import AuthNav from '../components/AuthNav';
import { useLocation } from 'react-router-dom';

const UserRoutes = () => {
  const isAuthenticated = getToken();
  const currentDate = new Date().toISOString().split('T')[0];
  const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

  const { events, loadingEvents } = useSelector((state) => state.getEvents);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchEvents())
  }, [dispatch]);

  const filteredEvents = !loadingEvents && events.length > 0
    ? events.filter((event) => {
      const eventDate = event.sessiondate.split('T')[0];
      return event.locationcategory === 'Webinar' && eventDate === currentDate;
    })
    : [];

  const [isVisible, setIsVisible] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('/live_session')) {
      setIsVisible(false);
    }
  }, [location.pathname]);

  const handleClose = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    signInAnonymously(auth)
      .then(() => {
        console.log('Signed in anonymously');
      })
      .catch((error) => {
        console.error('Error signing in anonymously', error);
      });
  }, []);

  const limitWords = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <Sidebar />
      <AuthNav />
      <div className="ml-0 p-0 md:ml-[13.8rem]">
        {filteredEvents.length > 0 ? (
          filteredEvents.map(event => (
            isVisible && (
              <div className="px-[4%] ">
                <div className="md:flex items-center justify-between gap-4 my-4 bg-[#E20BE308] rounded-[32px] shadow-md p-[24px]">
                  <div>
                    <p className="text-[24px] font-[600] workSans text-[#011114]">It&apos;s time for {event.sessionname}</p>
                    <p className="text-[#616161] font-[400] text-[16px] workSans">{limitWords(event.sessiondetails, 8)}</p>
                  </div>
                  <div className="flex gap-4 items-center my-2">
                    <Link to="/live_session" className="linear_gradient_btn text-white font-[600] text-[14px] workSans py-[8px] md:py-[10px] px-[15px] md:px-[40px] rounded-[32px]">Enter backstage</Link>
                    <button
                      type="button"
                      onClick={handleClose}
                      className="border-[2px] border-[#7C067D] text-[#7C067D] font-[600] text-[14px] workSans py-[8px] md:py-[10px] px-[15px] md:px-[40px] rounded-[32px]"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            )
          ))
        ) : (
          <div className="font-[400] text-[16px] workSans text-[#011114]">{loadingEvents ? "" : ""}</div>
        )}
        <Outlet />
      </div>
    </div>
  );
};

export default UserRoutes;
