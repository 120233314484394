import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../redux/users/fetchUsers';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchWithSession } from '../../redux/session';

const UserDetails = () => {
  const { userId } = useParams();
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [denyLoading, setDenyLoading] = useState(false);
  const [blockLoading, setblockLoading] = useState(false);
  const navigate = useNavigate();
  const { users, loadingUsers, usersError } = useSelector((state) => state.getUsers);
  const api_url = process.env.REACT_APP_BASE_API_URL;
  const user = users?.find((user) => user._id === userId);
  console.log("user", user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers())
  }, [dispatch])

  useEffect(() => {
    if (users?.length > 0) {
      if (!userId || !users.some(user => user._id === userId)) {
        navigate("/manage_users");
      }
    }
  }, [userId, users, navigate]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const verifyUser = async (userId, email) => {
    setVerifyLoading(true);
    try {
      const response = await fetchWithSession(`${api_url}/api/auth/verifyUser/${userId}`, {
        method: 'PUT',
        body: { email },
      });

      if (response.status === 200) {
        setVerifyLoading(false);
        dispatch(getUsers());
        alert('User verified successfully');
      } else {
        setVerifyLoading(false);
        console.log('Failed to verify user');
      }
    } catch (error) {
      alert('Error verifying user');
      setVerifyLoading(false);
    }
  };

  const handleVerifyUser = (userId, email) => {
    if (window.confirm('Are you sure you want to verify this user?')) {
      verifyUser(userId, email);
    }
  };

  const blockUser = async (userId, email) => {
    setblockLoading(true);
    try {
      const response = await fetchWithSession(`${api_url}/api/auth/blockUser/${userId}`, {
        method: 'PUT',
        body: { email },
      });

      if (response.status === 200) {
        setblockLoading(false);
        dispatch(getUsers());
        alert(response?.data?.message);
      } else {
        setblockLoading(false);
        console.log('Failed to block/unblock user');
      }
    } catch (error) {
      setblockLoading(false);
      console.error('Error blocking/unblocking user');
    }
  };

  const handleBlockUser = (userId, email) => {
    if (window.confirm('Are you sure you want to perform this action?')) {
      blockUser(userId, email);
    }
  };

  const denyUser = async (userId, email) => {
    setDenyLoading(true);
    try {
      const response = await fetchWithSession(`${api_url}/api/auth/denyUser/${userId}`, {
        method: 'PUT',
        body: { email },
      });

      if (response.status === 200) {
        setDenyLoading(false);
        dispatch(getUsers());
        alert('User denied successfully');
      } else {
        setDenyLoading(false);
        console.log('Failed to deny user');
      }
    } catch (error) {
      setDenyLoading(false);
      alert('Error denying user');
    }
  };

  const handleDenyUser = (userId, email) => {
    if (window.confirm('Are you sure you want to perform this action?')) {
      denyUser(userId, email);
    }
  };

  return (
    <div className="p-[4%]">
      <h2 className="text-[#121212] text-[28px] font-[700] workSans mb-6">User details</h2>
      {loadingUsers && <p className="text-[#616161] text-[16px] font-[500] workSans">Loading...</p>}
      {usersError && <p className="text-[#616161] text-[16px] font-[500] workSans">Error fetching user</p>}
      {user && (
        <div>
          <div className="flex items-end justify-between gap-6 mb-6">
            <div className="text-[#808080] text-[13px] font-[400] workSans mb-4">
              <p>
                <span className="font-medium">Status: </span>
                <span className={`px-[10px] py-[2px] ${user.status === "Active" ? "bg-[#15A6471A] text-[#15A647]" : user.status === "Blocked" ? "bg-[#C300001A] text-[#C30000]" : user.status === "Pending" ? "bg-[#FF99001A] text-[#FF9900]" : user.status === "Denied" ? "bg-[#FF99001A] text-[#C30000]" : ""} rounded-[8px] font-semibold`}>
                  {user.status === "Active" ? "Active" : user.status === "Blocked" ? "Inactive" : user.status === "Pending" ? "Pending" : user.status === "Denied" ? "Denied" : ""}
                </span>
              </p>
              <p>Full name</p>
              <p>Email address</p>
              <p>Country</p>
              <p>State</p>
              <p>Membership fee</p>
              <p>Completed modules</p>
              <p>Date Joined</p>
            </div>
            <div className="text-[#808080] text-[13px] font-[400] workSans mb-4">
              <p>{user.firstname} {user.lastname}</p>
              <p>{user.email || "Nil"}</p>
              <p>{user.country || "Nil"}</p>
              <p>{user.state || "Nil"}</p>
              <p>{user.amount <= 10 ? "$" : <p>&#8358;</p>} {user.amount || "Nil"}</p>
              <p>{user.completedModuleContents}</p>
              <p>{formatDate(user.createdAt)}</p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              {user.userValidId && (
                <div className="border border-gray-300 rounded-md flex flex-col p-4 items-center justify-center">
                  <img className="w-full" src={user.userValidId} alt="user ID Card" />
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-wrap justify-center mt-[10%] gap-4">
            <button
              type="button"
              onClick={() => handleVerifyUser(user._id, user.email)}
              className="linear_gradient_btn text-white font-[600] text-[14px] workSans py-[8px] lg:py-[18px] px-[20px] lg:px-[90px] rounded-[32px]"
            >
              {verifyLoading ? "Verifying user..." : "Approve user"}
            </button>
            <button
              type="button"
              className="border-[2px] border-[#E20BE3] text-black font-[600] text-[14px] workSans py-[8px] lg:py-[18px] px-[20px] lg:px-[90px] rounded-[32px]"
              onClick={() => handleBlockUser(user._id, user.email)}
            >
              {user.isBlocked === true ? `${blockLoading ? "Unblocking user..." : "Unblock user"}` : `${blockLoading ? "Blocking user..." : "Block user"}`}
            </button>
            <button
              type="button"
              className="linear_gradient_btn text-white font-[600] text-[14px] workSans py-[8px] lg:py-[18px] px-[20px] lg:px-[90px] rounded-[32px]"
              onClick={() => handleDenyUser(user._id, user.email)}
            >
              {denyLoading ? "restricting user..." : "Deny user"}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default UserDetails;
