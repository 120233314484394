import { configureStore } from '@reduxjs/toolkit';
import signinReducer from './auth/signin';
import getUserReducer from './auth/getUser';
import getUsersReducer from './users/fetchUsers';
import createVideoReducer from './videoCourses/uploadVideo';
import getVideosReducer from './videoCourses/getVideos';
import getEventsReducer from './event/events';
import createEventReducer from './event/createEvent';
import editEventReducer from './event/editEvent';
import getMerchReducer from './merch/merch';
import createMerchReducer from './merch/createMerch';
import editMerchReducer from './merch/editMerch';
import getOrdersReducer from './orders/orders';
import getResourcesReducer from './resources/resources';

const store = configureStore({
  reducer: {
    signin: signinReducer,
    getUser: getUserReducer,
    getUsers: getUsersReducer,
    createVideo: createVideoReducer,
    getVideos: getVideosReducer,
    getEvents: getEventsReducer,
    createEvent: createEventReducer,
    editEvent: editEventReducer,
    getMerch: getMerchReducer,
    createMerch: createMerchReducer,
    editMerch: editMerchReducer,
    getOrders: getOrdersReducer,
    getResources: getResourcesReducer,
  }
})

export default store;
