import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithSession } from '../session';

const FETCH_USERS = "FETCH_USERS";
const api_url = process.env.REACT_APP_BASE_API_URL;
const FETCH_USERS_URL = `${api_url}/api/auth/getusers`;

const initialState = {
  users: [],
  loadingUsers: false,
  usersError: false,
}

export const getUsers = createAsyncThunk(
  FETCH_USERS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchWithSession(`${FETCH_USERS_URL}`, {
        method: 'GET',
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const getUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_USERS}/pending`:
      return {
        ...state,
        loadingUsers: true,
        usersError: false
      }
    case `${FETCH_USERS}/fulfilled`:
      return {
        users: action.payload,
        loadingUsers: false,
        usersError: false
      }
    case `${FETCH_USERS}/rejected`:
      return {
        ...state,
        loadingUsers: false,
        usersError: true
      }
    default:
      return { ...state, loadingUsers: false, usersError: false };
  }
}

export default getUsersReducer;
