import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FiUpload } from "react-icons/fi";
import { storage } from "../../firebase";
import { postEvent } from '../../redux/event/createEvent';
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const CreateEvent = () => {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [sessionname, setSessionname] = useState('');
  const [category, setCategory] = useState('');
  const [locationcategory, setLocationcategory] = useState('');
  const [sessiontime, setSessiontime] = useState('');
  const [sessionmonth, setSessionmonth] = useState('');
  const [sessiondate, setSessiondate] = useState('');
  const [sessiondetails, setSessiondetails] = useState('');
  const [eventimage, setEventimage] = useState("");

  const areFieldsEmpty = () => {
    return !sessionname || !category || !locationcategory
      || !sessiontime || !sessionmonth
      || !sessiondate || !sessiondetails || !eventimage;
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const maxSizeInKB = 500; // 500 KB

    if (selectedFile) {
      if (selectedFile.type.startsWith('image/')) {
        const fileSizeInKB = selectedFile.size / 1024;
        if (fileSizeInKB > maxSizeInKB) {
          alert('File size exceeds 500KB. Please select a smaller file.');
          return;
        }

        setFile(selectedFile);
        const imagePreviewUrl = URL.createObjectURL(selectedFile);
        setImagePreviewUrl(imagePreviewUrl);
      } else {
        alert('Please select a valid image file.');
      }
    }
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select an event image to upload.');
      return;
    }

    const storageRef = ref(storage, `eventsImages/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file, {
      contentType: file.type
    });

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error('Upload failed', error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setEventimage(downloadURL);
          console.log("File available at", downloadURL);
        });
      }
    );
  };

  const createEvent = async () => {
    setLoading(true);
    const data = {
      sessionname,
      category,
      locationcategory,
      sessiontime,
      sessionmonth,
      sessiondate,
      sessiondetails,
      eventimage,
    };

    dispatch(postEvent(data)).then((res) => {
      if (res.error) {
        alert("error creating event")
        setLoading(false);
      } else {
        alert("event created successfully");
        setLoading(false);
        setTimeout(() => {
          navigate("/events");
          window.location.reload();
        }, 2000)
      }
    })
  };

  return (
    <div>
      <div className="p-[4%] w-full">
        <h2 className="text-[#121212] text-[28px] font-[700] workSans mb-6">Schedule event</h2>
        <div className="grid grid-cols-2 gap-6 mb-4">
          <div>
            <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="sessionName">Session name</label>
            <input
              id="sessionName"
              type="text"
              value={sessionname}
              onChange={(e) => setSessionname(e.target.value)}
              placeholder="Type session name here"
              className="w-full px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[8px] focus:outline-none focus:ring-2 focus:ring-indigo-600 text-[#BFBBBB] text-[16px] font-[400] workSans"
            />
          </div>
          <div>
            <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="category">Category</label>
            <select
              id="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="w-full px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[8px] focus:outline-none focus:ring-2 focus:ring-indigo-600 text-[#BFBBBB] text-[16px] font-[400] workSans"
            >
              <option>Select category</option>
              <option>Forex</option>
              <option>Crypto</option>
              <option>Tech</option>
              <option>Personal development</option>
              <option>Business</option>
              <option>Finance</option>
              <option>Faith</option>
            </select>
          </div>
          <div>
            <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="userCategory">Location category</label>
            <select
              id="difficulty"
              value={locationcategory}
              onChange={(e) => setLocationcategory(e.target.value)}
              className="w-full px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[8px] focus:outline-none focus:ring-2 focus:ring-indigo-600 text-[#BFBBBB] text-[16px] font-[400] workSans"
            >
              <option>Select location</option>
              <option>Physical event</option>
              <option>Webinar</option>
            </select>
          </div>
          <div>
            <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="time">Time</label>
            <div className="flex space-x-4">
              <input
                id="hour"
                type="time"
                value={sessiontime}
                onChange={(e) => setSessiontime(e.target.value)}
                placeholder="11 30 pm"
                className="w-full lg:w-1/3 px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[8px] focus:outline-none focus:ring-2 focus:ring-indigo-600 text-[#BFBBBB] text-[16px] font-[400] workSans"
              />
            </div>
          </div>
          <div>
            <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="date">Date</label>
            <input
              id="date"
              type="date"
              value={sessiondate}
              onChange={(e) => setSessiondate(e.target.value)}
              className="w-full px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[8px] focus:outline-none focus:ring-2 focus:ring-indigo-600 text-[#BFBBBB] text-[16px] font-[400] workSans"
            />
          </div>
          <div>
            <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="userCategory">Session month</label>
            <select
              id="month"
              value={sessionmonth}
              onChange={(e) => setSessionmonth(e.target.value)}
              className="w-full px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[8px] focus:outline-none focus:ring-2 focus:ring-indigo-600 text-[#BFBBBB] text-[16px] font-[400] workSans"
            >
              <option>Select month</option>
              <option>January</option>
              <option>February</option>
              <option>March</option>
              <option>April</option>
              <option>May</option>
              <option>June</option>
              <option>July</option>
              <option>August</option>
              <option>September</option>
              <option>October</option>
              <option>November</option>
              <option>December</option>
            </select>
          </div>
        </div>

        <div className="mb-6">
          <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="details">Details of the event</label>
          <textarea
            id="details"
            placeholder="Type details here"
            value={sessiondetails}
            onChange={(e) => setSessiondetails(e.target.value)}
            className="w-full h-24 px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[8px] focus:outline-none focus:ring-2 focus:ring-indigo-600 text-[#BFBBBB] text-[16px] font-[400] workSans"
          ></textarea>
        </div>

        <div className="mb-6">
          <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2">Upload cover image</label>
          <div className="bg-[#E20BE308] rounded-[8px] p-16 text-center">
            <input type="file" className="hidden" id="uploadImage" onChange={handleFileChange} />
            <label
              htmlFor="uploadImage"
              className="cursor-pointer text-[#E20BE3] flex flex-col items-center"
            >
              <FiUpload />
              <span className="font-[400] text-[16px] workSans">Upload image</span>
            </label>
          </div>
          {imagePreviewUrl && (
            <div className="mt-4">
              <img
                controls
                alt="eventImage preview"
                src={imagePreviewUrl}
                className="w-full h-[250px] rounded-[8px] mt-4"
              />
            </div>
          )}
          <button
            className="linear_gradient_btn text-white mt-1 text-[14px] workSans font-[400] px-2 py-[2px] rounded-md"
            onClick={handleUpload}
          >
            Upload image
          </button>
          <p className="text-[14px] workSans font-[400]">Upload Progress: {progress}%</p>
        </div>

        <div className="flex justify-center">
          <button
            onClick={createEvent}
            className={`linear_gradient_btn text-white font-[600] text-[14px] workSans py-[18px] px-[90px] ${areFieldsEmpty() ? 'opacity-50 cursor-not-allowed' : ''} rounded-[32px]`}
            disabled={areFieldsEmpty()}
          >
            {loading ? "Loading..." : "Create event"}
          </button>
        </div>
      </div>
    </div>
  )
}

export default CreateEvent;
