import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMerch } from '../../redux/merch/merch';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchWithSession } from '../../redux/session';
import { Link } from 'react-router-dom';

const MerchDetails = () => {
  const { merchId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const { merch, loadingMerch, merchError } = useSelector((state) => state.getMerch);
  const product = merch?.find((product) => product._id === merchId);
  const api_url = process.env.REACT_APP_BASE_API_URL;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMerch())
  }, [dispatch])

  useEffect(() => {
    if (merch?.length > 0) {
      if (!merchId || !merch.some(product => product._id === merchId)) {
        navigate("/merch");
      }
    }
  }, [merchId, merch, navigate]);

  const deleteProduct = async (productId) => {
    setLoading(true);
    try {
      const response = await fetchWithSession(`${api_url}/api/merch/deleteProduct/${productId}`, {
        method: 'DELETE',
      });

      if (response.status === 200) {
        setLoading(false);
        alert('Product deleted successfully');
        setTimeout(() => {
          window.location.reload();
        }, 1000)
      } else {
        console.error('Error deleting product:', response.data.message);
        setLoading(false);
        alert('Failed to delete product');
      }
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      alert('An error occurred while deleting the product');
    }
  };

  const handleDelete = () => {
    const confirmDelete = window.confirm("Are you sure you want to delete this product?");
    if (confirmDelete) {
      deleteProduct(merchId);
    }
  };

  return (
    <div>
      <div className="p-[4%] w-full">
        {loadingMerch && <p className="text-[#616161] text-[16px] font-[500] workSans">Loading product...</p>}
        {merchError && <p className="text-[#616161] text-[16px] font-[500] workSans">Error loading product</p>}
        {product && (
          <div>
            <h2 className="text-[#121212] text-[28px] font-[700] workSans mb-6">Item details</h2>
            <div className="flex items-start justify-between gap-6 mb-6">
              <div className="text-[#808080] text-[13px] font-[400] workSans mb-4">
                <p>Item name</p>
                <p>Price</p>
                <p>Quantity</p>
                {product?.productSizes?.length > 0 ? (
                  <div>
                    <p>Variations Available:</p>
                    {product.productSizes.map((item, index) => (
                      <div key={index} className="flex items-center gap-4">
                        <p>Variation: <b>{item.size}</b></p>
                        <p>Quantity: <b>{item.quantity}</b></p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>No variations available</p>
                )}
              </div>
              <div className="text-[#808080] text-[13px] font-[400] workSans mb-4">
                <p>{product.productname}</p>
                <p>$ {product.price}</p>
                <p>{product.quantity || "Null"}</p>
              </div>
            </div>
            <div className="mb-4">
              <img className="bg-gray-200 w-full h-[300px] rounded-lg" alt="productImage" src={product.productimage} />
            </div>
            <div className="flex justify-center mt-[10%] gap-4">
              <Link to={`/merch/edit/${product._id}`} className="linear_gradient_btn text-white font-[600] text-[14px] workSans py-[8px] lg:py-[18px] px-[20px] lg:px-[90px] rounded-[32px]">
                Edit Item
              </Link>
              <button
                className="border-[2px] border-[#E20BE3] text-black font-[600] text-[14px] workSans py-[8px] lg:py-[18px] px-[20px] lg:px-[90px] rounded-[32px]"
                onClick={handleDelete}
              >
                {loading ? "deleting..." : "Delete item"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default MerchDetails;
