import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithSession } from '../session';

const CREATE_EVENT = "CREATE_EVENT";
const api_url = process.env.REACT_APP_BASE_API_URL;
const CREATE_EVENT_URL = `${api_url}/api/event`;

const initialState = {
  event: [],
  loadingEvent: false,
  eventError: false,
}

export const postEvent = createAsyncThunk(
  CREATE_EVENT,
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetchWithSession(`${CREATE_EVENT_URL}`, {
        method: 'POST',
        body: data,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const createEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${CREATE_EVENT}/pending`:
      return {
        ...state,
        loadingEvent: true,
        eventError: false
      }
    case `${CREATE_EVENT}/fulfilled`:
      return {
        ...state,
        event: action.payload,
        loadingEvent: false,
        eventError: false
      }
    case `${CREATE_EVENT}/rejected`:
      return {
        ...state,
        loadingEvent: false,
        eventError: true
      }
    default:
      return { event: [], loadingEvent: false, eventError: false };
  }
}

export default createEventReducer;
