import React from 'react';
import { useNavigate } from 'react-router-dom';

const Paid = ({ paidOrders }) => {
  const navigate = useNavigate();

  return (
    <div>
      {paidOrders && (
        <div>
          {
            paidOrders?.map(order => (
              <div className="border-[1px] border-[#a2a2a7] rounded my-4 p-4 flex items-start gap-4 justify-between">
                {order?.items?.[0] && (
                  <div className="flex gap-2 items-start">
                    <div>
                      <img className="w-[104px]" src={order.items[0].productimage} alt={order.items[0].productname} />
                    </div>
                    <div>
                      <p className="text-[16px] workSans">{order.items[0].productname}</p>
                      <p className="text-[12px] workSans">order ID: {order._id}</p>
                      <p 
                      className={`px-[2px] py-[2px] w-fit rounded mt-6 text-[#fff] workSans text-[13px] ${order.deliveryStatus === 'Delivery in process' ? 'bg-blue-600' : order.deliveryStatus === 'Delivered' ? 'bg-green-500' : ''}`}
                      >
                        {order.deliveryStatus}
                      </p>
                    </div>
                  </div>
                )}
                <div>
                  <button 
                  onClick={() => navigate(`/orders/${order._id}`)}
                  className="text-[18px] rounded text-[#E20BE3] workSans p-2 hover:bg-[#e30be350]"
                  >View all orders
                  </button>
                </div>
              </div>
            ))
          }
        </div>
      )}
    </div>
  );
};

export default Paid;
