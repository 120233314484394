import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEvents } from '../../redux/event/events';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchWithSession } from '../../redux/session';
import { Link } from 'react-router-dom';

const EventDetails = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const { events, loadingEvents, eventsError } = useSelector((state) => state.getEvents);
  const event = events?.find((event) => event._id === eventId);
  console.log("events", event);
  const api_url = process.env.REACT_APP_BASE_API_URL;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchEvents())
  }, [dispatch])

  useEffect(() => {
    if (events?.length > 0) {
      if (!eventId || !events.some(event => event._id === eventId)) {
        navigate("/events");
      }
    }
  }, [eventId, events, navigate]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const deleteEventById = async (eventId) => {
    setLoading(true);
    try {
      const response = await fetchWithSession(`${api_url}/api/event/deleteEvent/${eventId}`, {
        method: 'DELETE',
      });

      if (response.status === 200) {
        setLoading(false);
        alert('Event deleted successfully');
        setTimeout(() => {
          window.location.reload();
        }, 1000)
      } else {
        console.log('Failed to delete the event:', response.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alert('Error deleting event');
    }
  };

  const handleDeleteClick = () => {
    if (window.confirm('Are you sure you want to delete this event?')) {
      deleteEventById(eventId);
    }
  };

  return (
    <div>
      <div className="p-[4%] w-full">
        {loadingEvents && <p className="text-[#616161] text-[16px] font-[500] workSans">Loading event...</p>}
        {eventsError && <p className="text-[#616161] text-[16px] font-[500] workSans">Error loading event</p>}
        {event && (
          <div>
            <h2 className="text-[#121212] text-[28px] font-[700] workSans mb-6">{event.sessionname}</h2>
            <p className="text-[#808080] text-[15px] font-[500] workSans mb-4">{event.sessiondetails}</p>
            <div className="mb-4">
              <img className="bg-gray-200 w-full h-[300px] rounded-lg" src={event.eventimage} alt="event_image" />
            </div>
            <div className="flex items-start justify-between gap-6 mb-6">
              <div className="text-[#808080] text-[13px] font-[400] workSans mb-4">
                <p>Category</p>
                <p>Location</p>
                <p>Num of Registrants</p>
                <p>Time</p>
                <p>Date</p>
              </div>
              <div className="text-[#808080] text-[13px] font-[400] workSans mb-4">
                <p>{event.category || "NIL"}</p>
                <p>{event.locationcategory || "NIL"}</p>
                <p>{event.registrationCount || "NIL"}</p>
                <p>{event.sessiontime || "NIL"}</p>
                <p>{formatDate(event.sessiondate) || "NIL"}</p>
              </div>
            </div>
            {event.registeredUsers.length > 0 && (
              <div>
                <p className="workSans text-[20px] font-[500]">Registered users</p>
                <div className="overflow-y-auto h-52 border border-gray-300">
                  <table className="min-w-full workSans bg-white">
                    <thead>
                      <tr>
                        <th className="py-2 px-4 border-b">Name</th>
                        <th className="py-2 px-4 border-b">Email</th>
                        <th className="py-2 px-4 border-b">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {event.registeredUsers.map((user) => (
                        <tr key={user.id} className="hover:bg-gray-100">
                          <td className="py-2 px-4 border-b">{user.name}</td>
                          <td className="py-2 px-4 border-b">{user.email}</td>
                          <td className="py-2 px-4 border-b">{formatDate(user.createdAt) || "NIL"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            <div className="flex justify-center mt-[10%] gap-4">
              <button
                onClick={handleDeleteClick}
                className="linear_gradient_btn text-white font-[600] text-[14px] workSans py-[8px] lg:py-[18px] px-[20px] lg:px-[90px] rounded-[32px]"
              >
                {loading ? "deleting..." : "Delete event"}
              </button>
              <Link to={`/events/edit/${event._id}`} className="border-[2px] border-[#E20BE3] text-black font-[600] text-[14px] workSans py-[8px] lg:py-[18px] px-[20px] lg:px-[90px] rounded-[32px]">
                Edit event
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default EventDetails;
