import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVideos } from '../../redux/videoCourses/getVideos';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchWithSession } from '../../redux/session';

const VideoDetails = () => {
  const { videoId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { videos, loadingVideos, videosError } = useSelector((state) => state.getVideos);
  const video = videos?.find((video) => video._id === videoId);
  const api_url = process.env.REACT_APP_BASE_API_URL;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchVideos());
  }, [dispatch]);

  useEffect(() => {
    if (videos?.length > 0) {
      if (!videoId || !videos.some(video => video._id === videoId)) {
        navigate("/video_courses");
      }
    }
  }, [videoId, videos, navigate]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const deleteVideo = async (videoId) => {
    setLoading(true);
    try {
      const response = await fetchWithSession(`${api_url}/api/livevideo/delete/${videoId}`, {
        method: 'DELETE',
      });

      if (response.status === 200) {
        setLoading(false);
        alert('Video deleted successfully');
        setTimeout(() => {
          window.location.reload();
        }, 2000)
      } else {
        setLoading(false);
        console.log('Failed to delete video');
      }
    } catch (error) {
      setLoading(false);
      alert('Error deleting video:', error);
    }
  };

  return (
    <div>
      <div className="p-[4%] w-fullxl">
        {loadingVideos && <p className="text-[#616161] text-[16px] font-[500] workSans">Loading Video...</p>}
        {videosError && <p className="text-[#616161] text-[16px] font-[500] workSans">Error loading Video</p>}
        {video && (
          <div>
            <h2 className="text-[#121212] text-[28px] font-[700] workSans mb-6">{video.videotitle}</h2>
            <p className="text-[#808080] text-[15px] font-[500] workSans mb-4">{video.videodescription}</p>
            <div className="flex items-start justify-between gap-6 mb-6">
              <div className="text-[#808080] text-[13px] font-[400] workSans mb-4">
                <p>Category</p>
                <p>Difficulty</p>
                <p>Duration</p>
                <p>Uploaded</p>
                <p>Number of views</p>
              </div>
              <div className="text-[#808080] text-[13px] font-[400] workSans mb-4">
                <p>{video.category}</p>
                <p>{video.difficulty}</p>
                <p>{video.duration}</p>
                <p>{formatDate(video.createdAt)}</p>
                <p className="text-[#E20BE3] hover:underline">{video.views}</p>
              </div>
            </div>
            <div className="mb-4">
              <video
                className="w-full h-[250px] z-50 custom-video"
                controls
              >
                <source src={video.videourl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="flex justify-center mt-[10%]">
              <button
                onClick={() => deleteVideo(video._id)}
                className="linear_gradient_btn text-white font-[600] text-[14px] workSans py-[18px] px-[90px] rounded-[32px]"
              >
                {loading ? "deleting..." : "Delete video"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default VideoDetails;
