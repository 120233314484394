import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchResources } from '../../redux/resources/resources';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchWithSession } from '../../redux/session';

const ManageModule = () => {
  const { resourceId, courseId, moduleId } = useParams();
  const api_url = process.env.REACT_APP_BASE_API_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { resources, loadingResources, resourcesError } = useSelector((state) => state.getResources);
  const resource = resources?.find((resource) => resource?.schooltitle.toLowerCase().replace(/\s+/g, '-') === resourceId);
  const course = resource?.schoolcourses.find((course) => course?.coursetitle.toLowerCase().replace(/\s+/g, '-') === courseId);
  const module = course?.coursemodules.find((module) => module?.moduletitle.toLowerCase().replace(/\s+/g, '-') === moduleId);

  useEffect(() => {
    dispatch(fetchResources());
  }, [dispatch]);

  const handleDelete = async (resourceID, courseID, moduleID) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this module?');
    if (!confirmDelete) return;
    try {
      const response = await fetchWithSession(`${api_url}/api/resource/${resourceID}/course/${courseID}/module/${moduleID}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        alert('Module deleted successfully');
        navigate(`/resources/school/${resourceId}/course/${courseId}`);
      } else {
        alert('Failed to delete module');
      }
    } catch (error) {
      console.error('Error deleting module:', error);
      alert('Error deleting module');
    }
  };

  return (
    <div className="p-[4%]">
      {loadingResources && <p className="text-[#616161] text-[16px] font-[500] workSans">Loading course...</p>}
      {resourcesError && <p className="text-[#616161] text-[16px] font-[500] workSans">Error loading course</p>}
      <div className="flex items-center justify-end gap-4">
        <button
          type="button"
          className="linearGradient my-4 rounded-[32px] text-[14px] font-[500] workSans text-[#fff]"
          onClick={() => navigate(`/edit_module/resource/${resource._id}/course/${course._id}/module/${module._id}`)}
        >
          Edit module
        </button>
        <button
          type="button"
          className="border-[2px] py-[11px] px-[35px] border-[#7C067D] my-4 rounded-[32px] text-[14px] font-[500] workSans text-[#7C067D]"
          onClick={() => handleDelete(resource._id, course._id, module._id)}
        >
          Delete module
        </button>
        <button
          type="button"
          className="linearGradient my-4 rounded-[32px] text-[14px] font-[500] workSans text-[#fff]"
          onClick={() => navigate(`/create_moduleContent/${module._id}`)}
        >
          Add module contents
        </button>
      </div>
      {resource && (
        <div>
          <h1 className="text-[#011114] text-[28px] font-[600] workSans">{module?.moduletitle}</h1>
          <img src={module?.moduleimg} alt="course_img" className="w-full md:w-[1280px] md:h-[300px] rounded-[16px]" />
          <p className="font-[500] text-[18px] my-4 workSans text-[#011114]">
            {module?.moduledesc}
          </p>
          {module?.modulecontent?.map((item, index) => (
            <div className="my-4" key={index}>
              {/* <p className="text-[#011114] text-[16px] font-[400] workSans">
                {item?.modulecontenttext}
              </p> */}
              <div
                dangerouslySetInnerHTML={{
                  __html: item?.modulecontenttext.replace(
                    /<img/g,
                    '<img style="width:400px;"'
                  )
                }}
                className="text-[#011114] font-[400] workSans"
              />
              {item?.modulecontentimage ? (<img src={item?.modulecontentimage} alt="course_img" className="w-full md:w-[400px] rounded-[16px] my-4" />) : (<></>)}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ManageModule;
