import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUsers } from '../../redux/users/fetchUsers';
import { Link } from 'react-router-dom';

const Users = () => {
  const { users, loadingUsers, usersError } = useSelector((state) => state.getUsers);
  console.log("users", users);
  const [searchFilter, setSearchFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const dispatch = useDispatch();

  const filterusers = users
    ?.filter((user) => user.firstname.toLowerCase().includes(searchFilter.toLowerCase()))
    .filter((user) => {
      if (statusFilter === "active") return user.isVerified;
      if (statusFilter === "pending") return !user.isVerified && !user.isBlocked;
      if (statusFilter === "inactive") return user.isBlocked;
      return true;
    });

  const totalUsers = filterusers?.length || 0;

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  return (
    <div className="p-[4%]">
      {loadingUsers && (
        <p className="font-[400] text-[20px] workSans pt-5 pb-3 text-[#011114]">Loading...</p>
      )}
      {usersError && (
        <p className="font-[400] text-[20px] workSans pt-5 pb-3 text-[#011114]">
          Error fetching users. Please check your internet connection and try refreshing the page.
        </p>
      )}
      {users && users.length > 0 ? (
        <div>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-[#231F20] font-[600] text-[24px]">User management <span className="bg-[#CC3300] rounded-[100px] py-[4px] px-[8px] text-white text-[12px] font-[500] workSans">{totalUsers}</span></h2>
          </div>
          <div className="flex flex-wrap gap-4 items-center justify-between">
            <div className="relative mb-4">
              <input
                type="text"
                placeholder="Search name"
                value={searchFilter}
                onChange={(e) => setSearchFilter(e.target.value)}
                className="w-full md:w-[500px] px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[16px] focus:outline-none focus:ring-2 focus:ring-[#792679] text-[#6D6662] text-[16px] font-[400] workSans"
              />
            </div>
            <div className="mb-4">
              <select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                className="px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[16px] focus:outline-none focus:ring-2 focus:ring-[#792679] text-[#6D6662] text-[16px] font-[400] workSans"
              >
                <option value="all">All Users</option>
                <option value="active">Active Users</option>
                <option value="pending">Pending Users</option>
                <option value="inactive">Inactive Users</option>
              </select>
            </div>
          </div>
          <div className="overflow-x-auto w-full border border-gray-300">
            <table className="min-w-full bg-white">
              <thead className="bg-black text-white py-2">
                <tr>
                  <th className="py-2 px-4 text-white font-[700] workSans text-[12px]">S.No</th>
                  <th className="py-2 px-4 text-white font-[700] workSans text-[12px]">Name</th>
                  <th className="py-2 px-4 text-white font-[700] workSans text-[12px]">Email address</th>
                  <th className="py-2 px-4 text-white font-[700] workSans text-[12px]">Status</th>
                  <th className="py-2 px-4 text-white font-[700] workSans text-[12px]"></th>
                </tr>
              </thead>
              <tbody>
                {filterusers.map((user, index) => (
                  <tr key={index} className="text-center odd:bg-white p-4 even:bg-[#5900640D]">
                    <td className="py-4 px-4 text-[14px] workSans font-[400] text-[#231F20]">{index + 1}.</td>
                    <td className="py-4 px-4 text-[14px] workSans font-[400] text-[#231F20]">{user.firstname} {user.lastname}</td>
                    <td className="py-4 px-4 text-[14px] workSans font-[400] text-[#231F20]">{user.email}</td>
                    <td className={`rounded-[8px] ${user.isVerified ? "bg-[#15A6471A] text-[#15A647]" : user.isBlocked ? "bg-[#C300001A] text-[#C30000]" : user.isVerified === false ? "bg-[#FF99001A] text-[#FF9900]" : ""} text-[14px] workSans font-[400]`}>
                      {user.isVerified ? "Active" : user.isBlocked ? "Inactive" : user.isVerified === false ? "Pending" : ""}
                    </td>
                    <Link to={`/manage_users/${user._id}`}>
                      <td className="py-4 px-4 text-[14px] workSans font-[400] text-[#E20BE3]">Review</td>
                    </Link>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="text-[#616161] text-[16px] font-[500] workSans">{loadingUsers ? "" : "No user found"}</div>
      )}
    </div>
  )
}

export default Users;
