import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../redux/auth/getUser';
import bell from "./images/bell.png"
import { FaBars } from 'react-icons/fa';
import Sidebar from './Sidebar';
import { IoMdClose } from "react-icons/io";

const AuthNav = () => {
  const { user, loadingUser, userError } = useSelector((state) => state.getUser);
  const dispatch = useDispatch();
  const userId = localStorage.getItem('userId');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchUser(userId));
  }, [dispatch, userId]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex items-center justify-between md:justify-end bg-white py-4 px-[5%] shadow-md">
      <div className="relative hidden md:block">
        <img src={bell} alt="bell" />
      </div>
      {loadingUser && (
        <p className="text-[20px] font-[400] workSans text-gray-900 mb-6">...</p>
      )}
      {userError && (
        <p className="text-[20px] font-[400] workSans text-gray-900 mb-6">...</p>
      )}
      {user && (
        <div className="flex items-center ml-6">
          <div className="flex items-center workSans justify-center w-10 h-10 text-[20px] font-[600] bg-[#5900641A] text-[#590064] rounded-full">
            {user?.firstname?.charAt(0) || "A"}
          </div>
          <p className="ml-2 text-[16px] workSans text-[#231F20] font-[600]">{user?.firstname || "User"} {user?.lastname || "Name"}</p>
        </div>
      )}

      <button className="md:hidden text-2xl" onClick={toggleSidebar}>
        {isSidebarOpen ? (<IoMdClose />) : (<FaBars />)}
      </button>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    </div>
  );
};

export default AuthNav;
