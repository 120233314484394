import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchResources } from '../../redux/resources/resources';
import { Link } from 'react-router-dom';

const Resources = () => {
  const { resources, loadingResources, resourcesError } = useSelector((state) => state.getResources);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    dispatch(fetchResources());
  }, [dispatch]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredResources = resources
    .filter((resource) =>
      resource.schooltitle.toLowerCase().includes(searchTerm.toLowerCase())
    )

  const limitWords = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  return (
    <div className="p-[4%]">
      <Link to="/resources/create_course">
        <div className="bg-[#59006480] rounded-[16px] p-[16px] mb-6">
          <div className="py-6">
            <div className="flex justify-center items-center">
              <button className="bg-[#590064] hover:bg-[#5800649b] text-white font-bold py-[4px] px-4 rounded-[12px]">
                <span className="text-[24px] font-[700] workSans">+</span>
              </button>
            </div>
            <p className="text-center text-[20px] font-[700] workSans text-white">Create a school</p>
          </div>
        </div>
      </Link>
      <h1 className="text-[28px] workSans font-[700] text-[#121212] mb-6">School management</h1>
      <div className="relative mb-6">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-full md:w-[500px] px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[16px] focus:outline-none focus:ring-2 focus:ring-[#792679] text-[#6D6662] text-[16px] font-[400] workSans"
          placeholder="Search courses"
        />
      </div>
      {loadingResources && <p className="text-[#616161] text-[16px] py-4 font-[500] workSans">Loading resources...</p>}
      {resourcesError && <p className="text-[#616161] text-[16px] font-[500] workSans">Error loading resources</p>}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6">
        {filteredResources && filteredResources.length > 0 ? (
          filteredResources.map((resource, index) => (
            <div key={index} className="my-4 gap-6 bg-[#5900641A] p-[40px] rounded-[16px]">
              <img src={resource.schoolimage} alt="course_image" className="w-[400px] h-[300px]" />
              <div>
                <p className="font-[600] text-[32px] workSans text-[#011114]">{resource.schooltitle}</p>
                <div className="flex items-center gap-4 py-[3%]">
                  <p className="text-[#590064] workSans text-[16px] font-[500]">{resource.schooldifficulty}</p>
                  <p className="text-[#590064] workSans text-[16px] font-[500]">|</p>
                  <p className="text-[#590064] workSans text-[16px] font-[500]">{resource.schoolcourses.length} topic{resource.schoolcourses.length > 1 ? 's' : ''}</p>
                </div>
                <p className="text-[#011114] text-[16px] font-[400] workSans">
                {limitWords(resource.schooldesc, 24)}
                </p>
                <button
                  type="button"
                  onClick={() => navigate(`/resources/school/${resource.schooltitle.toLowerCase().replace(/\s+/g, '-')}`)}
                  className="linearGradient my-4 rounded-[32px] text-[14px] font-[500] workSans text-[#fff]"
                >
                  Manage school
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="text-[#616161] text-[16px] font-[500] workSans">{loadingResources ? "" : "No resources found"}</div>
        )}
      </div>
    </div>
  )
}

export default Resources;
