import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithSession } from '../session';

const EDIT_EVENT = "EDIT_EVENT";
const api_url = process.env.REACT_APP_BASE_API_URL;
const EDIT_EVENT_URL = `${api_url}/api/event/editEvent`;

const initialState = {
  event: [],
  loadingEvent: false,
  eventError: false,
}

export const editEvent = createAsyncThunk(
  EDIT_EVENT,
  async ({ data, eventId }, { rejectWithValue }) => {
    try {
      const response = await fetchWithSession(`${EDIT_EVENT_URL}/${eventId}`, {
        method: 'PUT',
        body: data,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const editEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${EDIT_EVENT}/pending`:
      return {
        ...state,
        loadingEvent: true,
        eventError: false
      }
    case `${EDIT_EVENT}/fulfilled`:
      return {
        ...state,
        event: action.payload,
        loadingEvent: false,
        eventError: false
      }
    case `${EDIT_EVENT}/rejected`:
      return {
        ...state,
        loadingEvent: false,
        eventError: true
      }
    default:
      return state;
  }
}

export default editEventReducer;
