import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithSession } from '../session';

const CREATE_MERCH = "CREATE_MERCH";
const api_url = process.env.REACT_APP_BASE_API_URL;
const CREATE_MERCH_URL = `${api_url}/api/merch`;

const initialState = {
  merch: [],
  loadingMerch: false,
  merchError: false,
}

export const postMerch = createAsyncThunk(
  CREATE_MERCH,
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetchWithSession(`${CREATE_MERCH_URL}`, {
        method: 'POST',
        body: data,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const createMerchReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${CREATE_MERCH}/pending`:
      return {
        ...state,
        loadingMerch: true,
        merchError: false
      }
    case `${CREATE_MERCH}/fulfilled`:
      return {
        ...state,
        merch: action.payload,
        loadingMerch: false,
        merchError: false
      }
    case `${CREATE_MERCH}/rejected`:
      return {
        ...state,
        loadingMerch: false,
        merchError: true
      }
    default:
      return { ...state };
  }
}

export default createMerchReducer;
