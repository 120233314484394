import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { storage } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { fetchWithSession } from '../../redux/session';

const CreateModuleContent = () => {
  const api_url = process.env.REACT_APP_BASE_API_URL;
  const { moduleId } = useParams();
  const navigate = useNavigate();
  const { handleSubmit } = useForm();
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const sanitizedTextRef = useRef('');

  const handleImageUpload = async (file) => {
    if (!file || !file.name) {
      console.error('Invalid file object or file name:', file);
      throw new Error('File is undefined or does not have a valid name.');
    }

    try {
      const imageRef = ref(storage, `resources/moduleContentImages/${file.name}`);
      const uploadTask = uploadBytesResumable(imageRef, file, {
        contentType: file.type,
      });

      await uploadTask;

      let url = await getDownloadURL(imageRef);

      // Replace &amp; with & using a regular expression
      url = url.replace(/&amp;/g, '&');

      return url;
    } catch (error) {
      console.error('Error uploading image to Firebase:', error);
      throw error;
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const contentWithImage = sanitizedTextRef.current;
      const response = await fetchWithSession(`${api_url}/api/resource/module/content/${moduleId}`, {
        method: 'POST',
        body: {
          modulecontenttext: contentWithImage,
        },
      });
      setLoading(false);
      alert('Content uploaded');
    } catch (error) {
      setLoading(false);
      alert('Error uploading content:');
    }
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = await handleImageUpload(file);

      // Update and sanitize text content with the new image URL
      const updatedText = text + `<img src="${imageUrl}" alt="Image" />`;
      const sanitizedText = updatedText.replace(/&amp;/g, '&');

      sanitizedTextRef.current = sanitizedText; // Save sanitized text
      setText(sanitizedText); // Update editor content
    }
  };

  const handleEditorChange = (value) => {
    const sanitizedValue = value.replace(/&amp;/g, '&'); // Sanitize every time text changes
    sanitizedTextRef.current = sanitizedValue;
    setText(sanitizedValue);
  };

  return (
    <div className="p-[4%]">
      <div className="flex justify-between items-center gap-2 mb-8">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="text-[20px] text-[#121212] font-[400] workSans">
          &larr;
        </button>
        <h2 className="text-[28px] text-[#121212] font-[700] workSans">Create Module Content</h2>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Editor
          initialValue="<p>Start typing here...</p>"
          apiKey='kj9fdbxvs0b6uagzjzjkcaly6njogceqq22cf1ktow8a5eh9'
          init={{
            height: 500,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help'
          }}
          value={text}
          onEditorChange={handleEditorChange}
        />
        <input type="file" className="hidden" accept="image/*" id="uploadImage" onChange={handleImageChange} />
        <label
          htmlFor="uploadImage"
          className="cursor-pointer text-[#E20BE3] flex flex-col items-start"
        >
          <span className="font-[400] text-[16px] workSans">Choose file</span>
        </label>
        <button
          type="submit"
          className="linearGradient my-6 rounded-[32px] text-[14px] font-[500] workSans text-[#fff]"
        >
          {loading ? 'Creating...' : 'Save Content'}
        </button>
      </form>
    </div>
  );
};

export default CreateModuleContent;
