import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithSession } from '../session';

const EDIT_MERCH = "EDIT_MERCH";
const api_url = process.env.REACT_APP_BASE_API_URL;
const EDIT_MERCH_URL = `${api_url}/api/merch/editProduct`;

const initialState = {
  merch: [],
  loadingMerch: false,
  merchError: false,
}

export const editMerch = createAsyncThunk(
  EDIT_MERCH,
  async ({ data, merchId }, { rejectWithValue }) => {
    try {
      const response = await fetchWithSession(`${EDIT_MERCH_URL}/${merchId}`, {
        method: 'PUT',
        body: data,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const editMerchReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${EDIT_MERCH}/pending`:
      return {
        ...state,
        loadingMerch: true,
        merchError: false
      }
    case `${EDIT_MERCH}/fulfilled`:
      return {
        ...state,
        merch: action.payload,
        loadingMerch: false,
        merchError: false
      }
    case `${EDIT_MERCH}/rejected`:
      return {
        ...state,
        loadingMerch: false,
        merchError: true
      }
    default:
      return state;
  }
}

export default editMerchReducer;
