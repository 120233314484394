import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMerch } from '../../redux/merch/merch';
import { fetchWithSession } from '../../redux/session';
import { Link } from 'react-router-dom';

const ManageMerch = () => {
  const { merch, loadingMerch, merchError } = useSelector((state) => state.getMerch);
  const [loading, setLoading] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const api_url = process.env.REACT_APP_BASE_API_URL;

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredMerch = merch
    ?.filter((item) =>
      item.productname.toLowerCase().includes(searchTerm.toLowerCase())
    );

  useEffect(() => {
    dispatch(fetchMerch());
  }, [dispatch]);

  const limitWords = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  const deleteProduct = async (productId) => {
    setLoading(true);
    try {
      const response = await fetchWithSession(`${api_url}/api/merch/deleteProduct/${productId}`, {
        method: 'DELETE',
      });
      
      if (response.status === 200) {
        setLoading(false);
        alert('Product deleted successfully');
        setTimeout(() => {
          window.location.reload();
        }, 1000)
      } else {
        console.error('Error deleting product:', response.data.message);
        setLoading(false);
        alert('Failed to delete product');
      }
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      alert('An error occurred while deleting the product');
    }
  };

  const handleDelete = (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this product?");
    if (confirmDelete) {
      deleteProduct(id);
    }
  };

  return (
    <div className="p-[4%]">
      <Link to="/merch/create">
        <div className="bg-[#59006480] rounded-[16px] p-[16px] mb-6">
          <div className="py-6">
            <div className="flex justify-center items-center">
              <button className="bg-[#590064] hover:bg-[#5800649b] text-white font-bold py-[4px] px-4 rounded-[12px]">
                <span className="text-[24px] font-[700] workSans">+</span>
              </button>
            </div>
            <p className="text-center text-[20px] font-[700] workSans text-white">Create a merch</p>
          </div>
        </div>
      </Link>
      <h1 className="text-[28px] workSans font-[700] text-[#121212] mb-6">Shop</h1>
      <div className="relative mb-6">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-full md:w-[500px] px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[16px] focus:outline-none focus:ring-2 focus:ring-[#792679] text-[#6D6662] text-[16px] font-[400] workSans"
          placeholder="Search for item"
        />
      </div>
      {loadingMerch && (
        <p className="font-[400] text-[20px] workSans pt-5 pb-3 text-[#011114]">Loading products...</p>
      )}
      {merchError && (
        <p className="font-[400] text-[20px] workSans pt-5 pb-3 text-[#011114]">
          Error fetching products. Please check your internet connection and try refreshing the page.
        </p>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-6">
      {merch && merch.length > 0 ? (
        filteredMerch.map((product, index) => (
          <div className="bg-[#5900641A] rounded-[24px] p-[24px]" key={index}>
            <img
              className="w-full h-[250px] custom-product"
              src={product.productimage}
              alt={product.productname}
            />
            <div className="">
              <p className="font-[600] text-[18px] workSans pt-5 pb-3 text-[#011114]">{limitWords(product.productname, 4)}</p>
              <p className="font-[700] text-[24px] text-[#011114] mb-4 workSans">$ {product.price}</p>
            </div>
            <div className="flex flex-wrap items-center justify-center gap-2">
              <Link to={`/merch/${product._id}`}>
                <button
                  type="button"
                  className="bg-[#011114] rounded-[32px] py-[5px] md:py-[10px] px-[20px] md:px-[60px] font-[600] text-[14px] text-[#fff] workSans"
                >
                  Manage
                </button>
              </Link>
              <button
                type="button"
                onClick={() => handleDelete(product._id)}
                className="border-[#011114] border-[2px] rounded-[32px] py-[5px] md:py-[10px] px-[20px] md:px-[60px font-[600] text-[14px] text-[#011114] workSans"
              >
                {loading ? "deleting..." : "Delete"}
              </button>
            </div>
          </div>
        ))
      ) : (
        <div className="text-[#616161] text-[16px] font-[500] workSans">{loadingMerch ? "" : "No products found"}</div>
      )}
      </div>
    </div>
  )
}

export default ManageMerch;
