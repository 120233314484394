import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { storage } from "../../firebase";
import { useDispatch } from 'react-redux';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { postVideo } from '../../redux/videoCourses/uploadVideo';
import { FiUpload } from "react-icons/fi";

const UploadVideo = () => {
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [videotitle, setVideotitle] = useState("");
  const [videourl, setVideoUrl] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [duration, setDuration] = useState("");
  const [category, setCategory] = useState("");
  const [videodescription, setVideodescription] = useState("");
  const [videoPreviewUrl, setVideoPreviewUrl] = useState("");
  const dispatch = useDispatch();

  const areFieldsEmpty = () => {
    return !videotitle || !videourl || !difficulty || !duration || !category || !videodescription ;
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type.startsWith('video/')) {
      setFile(selectedFile);
      const videoPreviewUrl = URL.createObjectURL(selectedFile);
      setVideoPreviewUrl(videoPreviewUrl);
    } else {
      alert('Please select a valid video file.');
    }
  };

  const handleUpload = () => {
    if (!file) {
      alert('Please select a video to upload.');
      return;
    }

    const storageRef = ref(storage, `videos/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file, {
      contentType: file.type
    });

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error("Upload failed", error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setVideoUrl(downloadURL);
          console.log("File available at", downloadURL);
        });
      }
    );
  };

  const createVideo = () => {
    setLoading(true);
    const data = {
      videotitle, videourl, videodescription, category, difficulty, duration
    }
    dispatch(postVideo(data)).then((res) => {
      if (res.error) {
        console.log("error creating video", res.error)
        setLoading(false);
      } else {
        alert("Video created successfully");
        setLoading(false);
        setTimeout(() => {
          navigate("/video_courses");
          window.location.reload();
        }, 2000)
      }
    })
  };

  return (
    <div>
      <div className="p-[4%] w-full">
        <h2 className="text-[#121212] text-[28px] font-[700] workSans mb-6">Upload a course video</h2>
        <div className="grid grid-cols-2 gap-6 mb-4">
          <div>
            <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="sessionName">Video name</label>
            <input
              id="videoName"
              type="text"
              value={videotitle}
              onChange={(e) => setVideotitle(e.target.value)}
              placeholder="Type video name here"
              className="w-full px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[8px] focus:outline-none focus:ring-2 focus:ring-indigo-600 text-[#BFBBBB] text-[16px] font-[400] workSans"
            />
          </div>
          <div>
            <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="category">Category</label>
            <select
              id="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="w-full px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[8px] focus:outline-none focus:ring-2 focus:ring-indigo-600 text-[#BFBBBB] text-[16px] font-[400] workSans"
            >
              <option>Select category</option>
              <option>Forex</option>
              <option>Crypto</option>
              <option>Tech</option>
              <option>Personal development</option>
              <option>Finance</option>
              <option>Business</option>
              <option>Faith</option>
            </select>
          </div>
          <div>
            <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="userCategory">Difficulty</label>
            <select
              id="difficulty"
              value={difficulty}
              onChange={(e) => setDifficulty(e.target.value)}
              className="w-full px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[8px] focus:outline-none focus:ring-2 focus:ring-indigo-600 text-[#BFBBBB] text-[16px] font-[400] workSans"
            >
              <option>Select difficulty</option>
              <option>Beginner</option>
              <option>Intermediate</option>
              <option>Advanced</option>
            </select>
          </div>
          <div>
            <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="time">Duration</label>
            <input
              id="hour"
              type="text"
              placeholder="2hrs 30mins"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              className="w-full lg:w-1/5 px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[8px] focus:outline-none focus:ring-2 focus:ring-indigo-600 text-[#BFBBBB] text-[16px] font-[400] workSans"
            />
          </div>
        </div>

        <div className="mb-6">
          <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="details">Details of the video</label>
          <textarea
            id="details"
            placeholder="Type details here"
            value={videodescription}
            onChange={(e) => setVideodescription(e.target.value)}
            className="w-full h-24 px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[8px] focus:outline-none focus:ring-2 focus:ring-indigo-600 text-[#BFBBBB] text-[16px] font-[400] workSans"
          ></textarea>
        </div>

        <div className="mb-6">
          <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2">Upload Video</label>
          <div className="bg-[#E20BE308] rounded-[8px] p-16 text-center">
            <input type="file" className="hidden" id="uploadImage" onChange={handleFileChange} />
            <label
              htmlFor="uploadImage"
              className="cursor-pointer text-[#E20BE3] flex flex-col items-center"
            >
              <FiUpload />
              <span className="font-[400] text-[16px] workSans">Upload Video</span>
            </label>
          </div>
          {videoPreviewUrl && (
            <div className="mt-4">
              <video
                controls
                src={videoPreviewUrl}
                className="w-full h-[250px] rounded-[8px] mt-4"
              />
            </div>
          )}
          <button
            onClick={handleUpload}
            className="linear_gradient_btn text-white mt-1 text-[14px] workSans font-[400] px-2 py-[2px] rounded-md"
          >Upload Video</button>
          <p className="text-[14px] workSans font-[400]">Upload Progress: {progress}%</p>
        </div>

        <div className="flex justify-center">
          <button
            type="button"
            onClick={createVideo}
            className={`linear_gradient_btn text-white font-[600] text-[14px] workSans py-[18px] px-[90px] rounded-[32px] ${areFieldsEmpty() ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={areFieldsEmpty()}
          >
            {loading ? "Loading..." : "Create video"}
          </button>
        </div>
      </div>
    </div>
  )
}

export default UploadVideo;
