import React, { useState } from 'react';
import { FiUpload } from "react-icons/fi";
import { useParams, useNavigate } from 'react-router-dom';
import { storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { fetchWithSession } from '../../redux/session';

const EditModule = () => {
  const { resourceId, courseId, moduleId } = useParams();
  const [file, setFile] = useState(null);
  const api_url = process.env.REACT_APP_BASE_API_URL;
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [moduletitle, setModuletitle] = useState('');
  const [moduledesc, setModuledesc] = useState('');
  const [moduleimg, setModuleimg] = useState('');

  const navigate = useNavigate();

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const maxSizeInKB = 500; // 500 KB

    if (selectedFile) {
      if (selectedFile.type.startsWith('image/')) {
        const fileSizeInKB = selectedFile.size / 1024;
        if (fileSizeInKB > maxSizeInKB) {
          alert('File size exceeds 500KB. Please select a smaller file.');
          return;
        }

        setFile(selectedFile);
        const imagePreviewUrl = URL.createObjectURL(selectedFile);
        setImagePreviewUrl(imagePreviewUrl);
      } else {
        alert('Please select a valid image file.');
      }
    }
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select an image to upload.');
      return;
    }

    const storageRef = ref(storage, `resources/moduleImages/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file, {
      contentType: file.type
    });

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error('Upload failed', error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setModuleimg(downloadURL);
          console.log("File available at", downloadURL);
        });
      }
    );
  };

  const handleEditModule = async () => {
    setLoading(true);
    const data = {
      moduletitle,
      moduledesc,
      moduleimg,
    };

    const url = `${api_url}/api/resource/${resourceId}/course/${courseId}/module/${moduleId}`;
    const options = {
      method: 'PUT',
      body: data,
    };

    try {
      const response = await fetchWithSession(url, options);
      if (response.status === 200) {
        alert('Module edited successfully');
        setLoading(false);
        navigate("/resources")
        window.location.reload();
      } else {
        alert('Failed to edit module');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error editing module:', error);
      setLoading(false);
      alert(`Error editing module: ${error?.response?.data?.error}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-[4%]">
      <h2 className="text-[28px] text-[#121212] font-[700] workSans">Edit Module</h2>

      <div className="grid grid-cols-3 gap-6 mb-6">
        <div className="col-span-2">
          <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2">Module title</label>
          <input
            type="text"
            placeholder="Type school name here"
            value={moduletitle}
            onChange={(e) => setModuletitle(e.target.value)}
            className="w-full px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[8px] focus:outline-none focus:ring-2 focus:ring-indigo-600 text-[#BFBBBB] text-[16px] font-[400] workSans"
          />
        </div>
      </div>

      <div className="mb-6">
        <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2">Module Description</label>
        <textarea
          value={moduledesc}
          onChange={(e) => setModuledesc(e.target.value)}
          className="h-32 w-full px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[8px] focus:outline-none focus:ring-2 focus:ring-indigo-600 text-[#BFBBBB] text-[16px] font-[400] workSans"
          placeholder="Enter general overview here"
        />
      </div>

      <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2">Cover image</label>
      <div className="grid grid-cols-2 gap-6 mb-6">
        <div className="flex flex-col">
          <div className="relative">
            <img
              src={imagePreviewUrl || "https://via.placeholder.com/150"}
              alt="Cover"
              className="w-full h-32 object-cover rounded-lg border border-gray-300"
            />
          </div>
        </div>
        <div>
          <div className="flex flex-col justify-center items-center bg-[#E20BE308] rounded-[8px] text-center h-32 cursor-pointer hover:border-pink-500">
            <input type="file" className="hidden" id="uploadImage" onChange={handleFileChange} />
            <label
              htmlFor="uploadImage"
              className="cursor-pointer text-[#E20BE3] flex flex-col items-center"
            >
              <FiUpload />
              <span className="font-[400] text-[16px] workSans">Upload image</span>
            </label>
          </div>
          <div className="flex gap-4 items-center">
            <button
              className="linear_gradient_btn text-white mt-1 text-[14px] workSans font-[400] px-2 py-[2px] rounded-md"
              onClick={handleUpload}
            >
              Upload image
            </button>
            {imagePreviewUrl && (
              <p className={`text-[14px] workSans font-[400] ${moduleimg ? "text-green-500" : "text-red-500"}`}>
                {moduleimg ? "Image ready for modification" : "Image not ready for modification (click upload image)"}
              </p>
            )}
          </div>
          <p className="text-[14px] workSans font-[400]">Upload Progress: {progress}%</p>
        </div>
      </div>
      <div className="flex items-center justify-center gap-4 mt-4">
        <button
          type="button"
          onClick={handleEditModule}
          className="linearGradient my-4 rounded-[32px] text-[14px] font-[500] workSans text-[#fff]"
        >
          {loading ? 'Editing...' : 'Edit Module'}
        </button>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="border-[2px] py-[11px] px-[35px] border-[#7C067D] my-4 rounded-[32px] text-[14px] font-[500] workSans text-[#7C067D]"
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

export default EditModule;
