import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import womenInForex from "../images/women in tech.jpg";
import logo from "../images/logoblack.png";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signin } from '../../redux/auth/signin';
import "../index.css";

const Signin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const signinUser = () => {
    const user = { email, password }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      toast.warning("Please add a valid email", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    setLoading(true);
    dispatch(signin(user)).then((res) => {
      if (res.error) {
        toast.error(res?.payload, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      } else {
        toast.success(res?.payload?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
        setTimeout(() => {
          navigate("/")
          window.location.reload();
        }, 2000)
      }
    });
    setEmail("");
    setPassword("");
  }

  const areFieldsEmpty = () => {
    return !email || !password;
  };

  return (
    <div className="homepageHero2 min-h-screen px-[5%] w-full flex items-center justify-center">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="flex justify-center">
        <div className="bg-[#FFFFFF] flex items-baseine gap-[5%] rounded-[24px] p-6 my-[5%]">
          <div>
            <img src={womenInForex} alt="auth_image" className="w-full hidden lg:block md:w-[636px] md:h-[610px] rounded-3xl" />
          </div>
          <div>
            <div className="pt-3 pb-8">
              <Link to="/"><img src={logo} alt="logo" /></Link>
              <p className="text-[#011114] text-[30px] font-[700] workSans my-3">Log in</p>
              <p className="text-[#011114] text-[16px] pb-4 font-[400] workSans">
                Welcome back! Let’s get back on track.
              </p>
              <form>
                <div className="mb-4">
                  <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="email">
                    Email address
                  </label>
                  <input
                    className="appearance-none border-[#D9D9D9] border-[1px] rounded-[8px] w-full md:w-[400px] py-3 px-3 text-[#3d3d3d] text-[16px] font-[400] workSans leading-tight focus:outline-none"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    type="email"
                    placeholder="mmajukaris@gmail.com"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="password">
                    Enter Password
                  </label>
                  <input
                    className="appearance-none border-[#D9D9D9] border-[1px] rounded-[8px] w-full md:w-[400px] py-3 px-3 text-[#3d3d3d] text-[16px] font-[400] workSans leading-tight focus:outline-none"
                    id="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    placeholder="********"
                  />
                </div>
              </form>
              <button
                type="button"
                onClick={signinUser}
                disabled={areFieldsEmpty()}
                className={`linearGradient rounded-[32px] w-full text-[14px] font-[500] workSans text-[#fff] ${areFieldsEmpty() ? 'cursor-not-allowed' : ''}`}
              >
                {loading ? "loading..." : "Log in"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
