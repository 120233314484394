import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { fetchOrders } from '../../redux/orders/orders';
import { fetchWithSession } from '../../redux/session';

const OrderDetails = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const api_url = process.env.REACT_APP_BASE_API_URL;
  const { orders, loadingOrders, ordersError } = useSelector((state) => state.getOrders);

  const order = orders?.find((order) => order._id === orderId);
  console.log(order);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch])

  useEffect(() => {
    if (orders?.length > 0) {
      if (!orderId || !orders.some(order => order._id === orderId)) {
        navigate("/orders");
      }
    }
  }, [orderId, orders, navigate]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const markOrderAsDelivered = async (orderId) => {
    setLoading(true);
    try {
      const response = await fetchWithSession(`${api_url}/api/order/mark_order_as_delivered/${orderId}`, {
        method: 'PUT',
      });

      if (response.status === 200) {
        setLoading(false);
        alert('Order status changed successfully');
        setTimeout(() => {
          window.location.reload();
        }, 1000)
      } else {
        console.log('Failed to change order status :', response.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alert('Error changing order status');
    }
  };

  const handleOrderChange = () => {
    if (window.confirm('Are you sure you want to perform this action?')) {
      markOrderAsDelivered(orderId);
    }
  };

  return (
    <section className="py-[2%] px-[4%]">
      <div className="flex items-center gap-4">
        <div>
          <Link to="/orders">&larr;</Link>
        </div>
        <h1 className="baskerville font-[400] text-[20px] text-[#011114]">
          Order details
        </h1>
      </div>
      <hr />
      {loadingOrders && <p className="text-[#616161] text-[16px] font-[500] workSans">Loading order...</p>}
      {ordersError && <p className="text-[#616161] text-[16px] font-[500] workSans">Error loading order</p>}
      {order && (
        <div>
          <p className="text-[18px] font-[500] text-[#011114] workSans py-4">order ID: {order._id}</p>
          <p className="text-[12px] font-[400] text-[#011114] workSans">{order.items.length} items</p>
          <p className="text-[12px] font-[400] text-[#011114] workSans">placed on {formatDate(order.createdAt)}</p>
          <p className="text-[12px] font-[400] text-[#011114] workSans">Total $ {order.orderTotal}</p>
          <hr />
          <p className="text-[18px] text-[#011114] mt-4 baskerville">Items ordered</p>
          {order?.items?.map((item) =>
            <div className="flex gap-2 items-start mb-4 border-[1px] border-[#a2a2a7] rounded p-4">
              <div key={item._id}>
                <img className="w-[104px]" src={item.productimage} alt={item.productname} />
              </div>
              <div>
                <p className="text-[16px] workSans">{item.productname}</p>
                <p className="text-[12px] workSans">order ID: {order._id}</p>
                <p className="text-[12px] workSans">quantity: {item.quantity}</p>
                {item.selectedSize && (
                  <p className="text-[12px] workSans">variation: {item.selectedSize}</p>
                )}
                <p
                  className={`px-[2px] py-[2px] w-fit rounded mt-6 text-[#fff] workSans text-[13px] ${order.deliveryStatus === 'Delivery in process' ? 'bg-blue-600' : order.deliveryStatus === 'Delivered' ? 'bg-green-500' : ''}`}
                >
                  {order.deliveryStatus}
                </p>
              </div>
            </div>
          )}
          <div className="flex flex-col md:flex-row gap-6">
            {/* Payment Information */}
            <div className="border workSans border-gray-300 rounded-lg p-4 w-full md:w-1/2">
              <h3 className="text-sm font-medium text-gray-600 mb-4">PAYMENT INFORMATION</h3>
              <div className="mb-4">
                <h4 className="text-sm font-medium">Payment Method</h4>
                <p className="text-sm text-gray-500">{order.paymentMethod}</p>
              </div>
              <div>
                <h4 className="text-sm font-medium">Payment Details</h4>
                <p className="text-sm text-gray-500">Items total: $ {order.orderTotal}</p>
                <p className="text-sm font-medium">Total: $ {order.orderTotal}</p>
              </div>
            </div>

            {/* Delivery Information */}
            <div className="border workSans border-gray-300 rounded-lg p-4 w-full md:w-1/2">
              <h3 className="text-sm font-medium text-gray-600 mb-4">DELIVERY INFORMATION</h3>
              <div className="mb-4">
                <h4 className="text-sm font-medium">Delivery Method</h4>
                <p className="text-sm text-gray-500">Door Delivery</p>
              </div>
              <div className="mb-4">
                <h4 className="text-sm font-medium">Shipping Address</h4>
                <p className="text-sm text-gray-500">
                  {order.firstName} {order.lastName}<br />
                  {order.deliveryAddress}<br />
                  {order.city}, {order.region}
                </p>
              </div>
              <div>
                <h4 className="text-sm font-medium">Shipping Details</h4>
                <p className="text-sm text-gray-500">
                  Door Delivery by TheWaveTribe<br />
                </p>
              </div>
            </div>
          </div>
          {order.paymentStatus === "Paid" && (
            <div className="flex justify-center items-center">
              <button
                onClick={handleOrderChange}
                className="linear_gradient_btn text-white font-[600] my-4 text-[14px] workSans py-[18px] px-[90px] rounded-[32px]"
              >
                {loading ? "loading..." : "Mark order as delivered"}
              </button>
            </div>
          )}
        </div>
      )}
    </section>
  )
}

export default OrderDetails;
