import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEvents } from '../../redux/event/events';
import { Link } from 'react-router-dom';

const Events = () => {
  const { events, loadingEvents, eventsError } = useSelector((state) => state.getEvents);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredEvents = events
    .filter((event) =>
      event.sessionname.toLowerCase().includes(searchTerm.toLowerCase())
    );

  useEffect(() => {
    dispatch(fetchEvents())
  }, [dispatch]);

  const limitWords = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };


  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <h1 className="text-[28px] workSans font-[700] text-[#121212] mb-6">Upcoming events</h1>

      <Link to="/events/create_video">
        <div className="bg-[#59006480] rounded-[16px] p-[16px] mb-6">
          <div className="py-6">
            <div className="flex justify-center items-center">
              <button className="bg-[#590064] hover:bg-[#5800649b] text-white font-bold py-[4px] px-4 rounded-[12px]">
                <span className="text-[24px] font-[700] workSans">+</span>
              </button>
            </div>
            <p className="text-center text-[20px] font-[700] workSans text-white">Schedule an event</p>
          </div>
        </div>
      </Link>

      <div className="relative mb-6">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-full md:w-[500px] px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[16px] focus:outline-none focus:ring-2 focus:ring-[#792679] text-[#6D6662] text-[16px] font-[400] workSans"
          placeholder="Search event"
        />
      </div>
      {loadingEvents && <p className="text-[#616161] text-[16px] py-4 font-[500] workSans">Loading events...</p>}
      {eventsError && <p className="text-[#616161] text-[16px] py-4 font-[500] workSans">Error loading events: {eventsError}</p>}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {filteredEvents && filteredEvents.length > 0 ? (
          filteredEvents.map((event) => (
            <div key={event._id} className="bg-[#E20BE308] rounded-[32px] shadow-md p-[24px]">
              <img
                src={event.eventimage}
                alt="event_image"
                className="rounded-md h-[250px] w-full mb-4"
              />
              <h2 className="text-[24px] font-[600] workSans text-[#011114] mb-2">{event.sessionname}</h2>
              <p className="text-[16px] font-[400] workSans text-[#011114] mb-4">
                {limitWords(event.sessiondetails, 18)}
              </p>
              <p className="text-[14px] font-[700] workSans text-[#011114] mb-4">{event.sessiontime}</p>
              <Link to={`/events/${event._id}`} className="linear_gradient_btn text-white font-[600] text-[14px] workSans py-[18px] px-[90px] rounded-[32px]">
                View
              </Link>
            </div>
          ))
        ) : (
          <div className="text-[#616161] text-[16px] font-[500] workSans">{loadingEvents ? "" : "No events found for this month"}</div>
        )}
      </div>
    </div>
  )
}

export default Events;
