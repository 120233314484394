import React, { useState, useEffect } from 'react';
import { fetchOrders } from '../../redux/orders/orders';
import { useSelector, useDispatch } from 'react-redux';
import Paid from './orderStatus/Paid';
import Pending from './orderStatus/Pending';

const Orders = () => {
  const { orders, loadingOrders } = useSelector((state) => state.getOrders);
  const [searchTerm, setSearchTerm] = useState("");
  const [tabNum, setTabNum] = useState(0);
  const dispatch = useDispatch();

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const paidOrders = orders?.filter((order) => order.paymentStatus === "Paid").filter((order) =>
    order._id.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const pendingOrders = orders?.filter((order) => order.paymentStatus === "Pending").filter((order) =>
    order._id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch])

  const switchTab = () => {
    switch (tabNum) {
      case 0:
        return <Paid paidOrders={paidOrders} />
      case 1:
        return <Pending pendingOrders={pendingOrders} />
      default:
        return <Paid />
    }
  }

  const handleTab = (num) => {
    setTabNum(num);
  }

  return (
    <section className="px-[4%] pb-[4%]">
      <h1 className="text-[28px] workSans font-[700] text-[#121212] mb-6">Orders</h1>
      {loadingOrders && (
        <p className="font-[400] text-[20px] workSans pt-5 pb-3 text-[#011114]">Loading orders...</p>
      )}
      <div className="relative mb-6">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-full md:w-[500px] px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[16px] focus:outline-none focus:ring-2 focus:ring-[#792679] text-[#6D6662] text-[16px] font-[400] workSans"
          placeholder="Search by order ID"
        />
      </div>
      {orders && orders.length > 0 ? (
        <div className="w-full my-4 rounded shadow-md bg-[#fff] p-4">
          <div className="flex items-center gap-4">
            <button onClick={() => handleTab(0)} className={`text-[#75757A] ${tabNum === 0 ? 'text-[#E20BE3]' : ''} hover:text-[#E20BE3] text-[14px] workSans`}>
              PAID/DELIVERED ({paidOrders.length})
            </button>
            <button onClick={() => handleTab(1)} className={`text-[#75757A] ${tabNum === 1 ? 'text-[#E20BE3]' : ''} hover:text-[#E20BE3] text-[14px] workSans`}>
              FAILED PAYMENTS/CANCELED ({pendingOrders.length})
            </button>
          </div>
          <hr className="my-2" />
          {switchTab()}
        </div>
      ) : (
        <div className="text-[#616161] text-[16px] font-[500] workSans">{loadingOrders ? "" : "No orders found"}</div>
      )}
    </section>
  )
}

export default Orders;
