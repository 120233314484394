import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getUsers } from '../../redux/users/fetchUsers';
import { fetchEvents } from '../../redux/event/events';
import PerformanceChart from './PerformanceChart';
import { FaUsers } from "react-icons/fa";
import { MdOutlineOnlinePrediction } from "react-icons/md";
import { GrResources } from "react-icons/gr";
import { TbTimelineEventPlus } from "react-icons/tb";
import "../index.css";

const Dashboard = () => {
  const { users, loadingUsers, usersError } = useSelector((state) => state.getUsers);
  const { events, loadingEvents, eventsError } = useSelector((state) => state.getEvents);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialData = [30, 70, 90, 40, 20, 60, 1, 1, 1];
  const [chartData, setChartData] = useState(initialData);

  useEffect(() => {
    dispatch(getUsers());
    dispatch(fetchEvents());
  }, [dispatch]);

  const totalModulesCompleted = users?.reduce((module, item) => module + item.completedModuleContents, 0);
  const totalPendingUsers = users?.reduce((count, user) => count + !user.isVerified, 0);
  const totalUsers = users?.length || 0;
  const filteredUsers = users?.filter((user) => user.isVerified === false);
  const onLineEvents = events.filter((event) => event.locationcategory === "Webinar");
  const physicalEvents = events.filter((event) => event.locationcategory === "Physical event");
  const totalOnLineEvents = onLineEvents?.length || 0;
  const totalPhysicalEvents = physicalEvents?.length || 0;

  const handleCardClick = (newData) => {
    setChartData(newData);
  };

  const userDetails = [
    {
      name: "Online events",
      num: totalOnLineEvents || 0,
      icon: <MdOutlineOnlinePrediction className="text-white text-[15px] lg:text-[25px]" />,
      data: [30, 70, 90, 40, 20, 60, 1, 1, 1],
    },
    {
      name: "Users",
      num: totalUsers || 0,
      icon: <FaUsers className="text-white text-[15px] lg:text-[25px]" />,
      data: [30, 70, 0, 40, 20, 60, 10, 1, 1],
    },
    {
      name: "Course completions",
      num: totalModulesCompleted || 0,
      icon: <GrResources className="text-white text-[15px] lg:text-[25px]" />,
      data: [40, 80, 10, 90, 0, 1, 90, 80],
    },
    {
      name: "Physical events",
      num: totalPhysicalEvents || 0,
      icon: <TbTimelineEventPlus className="text-white text-[15px] lg:text-[25px]" />,
      data: [50, 70, 70, 110, 80, 20, 90, 70],
    },
  ];

  return (
    <div className="px-[4%]">
      <h1 className="text-[#121212] text-[28px] py-6 font-[500] workSans">Hey Admin welcome back!</h1>
      {loadingUsers && (
        <p className="font-[400] text-[20px] workSans pt-5 pb-3 text-[#011114]">Loading...</p>
      )}
      {usersError && (
        <p className="font-[400] text-[20px] workSans pt-5 pb-3 text-[#011114]">
          Error fetching users. Please check your internet connection and try refreshing the page.
        </p>
      )}
      <div className="flex flex-wrap items-center gap-6">
        {userDetails.map((user, index) => (
          <div
            className="shadow dashboard_shadow bg-white cursor-pointer flex justify-between items-center gap-6 p-[16px] rounded-[16px] lg:w-[290px]"
            onClick={() => handleCardClick(user.data)}
            key={index}
          >
            <div>
              <p className="text-[#A0AEC0] font-[700] text-[15px] workSans">{user.name}</p>
              <p className="text-[#2D3748] text-[20px] font-[700] workSans">
                {user.num}
              </p>
            </div>
            <div className="bg-[#590064] rounded-[12px] p-2">
              {user.icon}
            </div>
          </div>
        ))}
      </div>
      <div className="mt-4">
        <PerformanceChart data={chartData} />
      </div>
      {users && users.length > 0 ? (
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-[#231F20] font-[600] text-[20px]">Pending user requests <span className="bg-[#CC3300] rounded-[100px] py-[4px] px-[8px] text-white text-[12px] font-[500] workSans">{totalPendingUsers}</span></h2>
            <a href="/manage_users" className="text-[#E20BE3] font-[700] text-[14px] underline">View All</a>
          </div>
          <div className="overflow-x-auto w-full border border-gray-300">
            <table className="min-w-full bg-white">
              <thead className="bg-black text-white py-2">
                <tr>
                  <th className="py-2 px-4 text-white font-[700] workSans text-[12px]">S.No</th>
                  <th className="py-2 px-4 text-white font-[700] workSans text-[12px]">Name</th>
                  <th className="py-2 px-4 text-white font-[700] workSans text-[12px]">Email address</th>
                  <th className="py-2 px-4 text-white font-[700] workSans text-[12px]"></th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.slice(0, 5).map((user, index) => (
                  <tr key={index} className="text-center cursor-pointer odd:bg-white even:bg-[#5900640D]" onClick={() => navigate(`/manage_users/${user._id}`)}>
                    <td className="py-2 px-4 text-[14px] workSans font-[400] text-[#231F20]">{index + 1}.</td>
                    <td className="py-2 px-4 text-[14px] workSans font-[400] text-[#231F20]">{user.firstname} {user.lastname}</td>
                    <td className="py-2 px-4 text-[14px] workSans font-[400] text-[#231F20]">{user.email}</td>
                    <td className="py-2 px-4 text-[14px] workSans font-[400] text-[#231F20]">
                      <div className="flex justify-end gap-6">
                        <button className="bg-white z-50 text-black border-[2px] border-black rounded-[32px] px-4 py-1 hover:bg-black hover:text-white transition text-[16px] font-[500] workSans">Deny</button>
                        <button className="bg-black z-50 text-white rounded-[32px] px-4 py-1 hover:bg-white hover:text-black border border-black transition text-[16px] font-[500] workSans">Approve</button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="text-[#616161] text-[16px] font-[500] workSans">{loadingUsers ? "" : "No user found"}</div>
      )}
    </div>
  )
}

export default Dashboard;
