import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { fetchWithSession } from '../../redux/session';
import { useNavigate } from 'react-router-dom';

const CreateCourses = () => {
  const api_url = process.env.REACT_APP_BASE_API_URL;
  const { resourceId } = useParams();
  const [coursetitle, setCourseTitle] = useState('');
  const [coursedesc, setCourseDesc] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = { coursetitle, coursedesc };

    try {
      const response = await fetchWithSession(`${api_url}/api/resource/course/${resourceId}`, {
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 201) {
        alert('Course created successfully');
        setLoading(false);
        window.location.reload();
      } else {
        setLoading(false);
        alert(response?.data?.error || 'Failed to create course');
      }
    } catch (error) {
      console.error('Error creating course:', error);
      setLoading(false);
      alert(`Error creating course: ${error?.response?.data?.error}`);
    }
  };

  return (
    <div className="p-[4%]">
      <div className="flex justify-between items-center gap-2 mb-8">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="text-[28px] text-[#121212] font-[400] workSans">
          &larr;
        </button>
        <h2 className="text-[28px] text-[#121212] font-[700] workSans">Create course</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-3 gap-6 mb-6">
          <div className="col-span-2">
            <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2">course name</label>
            <input
              type="text"
              placeholder="Type course name here"
              value={coursetitle}
              onChange={(e) => setCourseTitle(e.target.value)}
              required
              className="w-full px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[8px] focus:outline-none focus:ring-2 focus:ring-indigo-600 text-[#BFBBBB] text-[16px] font-[400] workSans"
            />
          </div>
        </div>

        <div className="mb-6">
          <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2">Description</label>
          <textarea
            value={coursedesc}
            onChange={(e) => setCourseDesc(e.target.value)}
            className="h-32 w-full px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[8px] focus:outline-none focus:ring-2 focus:ring-indigo-600 text-[#BFBBBB] text-[16px] font-[400] workSans"
            placeholder="Enter course description here"
          />
        </div>
        <div className="flex items-center justify-center gap-4 mt-4">
          <button
            type="submit"
            disabled={loading}
            className="linearGradient my-4 rounded-[32px] text-[14px] font-[500] workSans text-[#fff]"
          >
            {loading ? 'Creating...' : 'Create Course'}
          </button>
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="border-[2px] py-[11px] px-[35px] border-[#7C067D] my-4 rounded-[32px] text-[14px] font-[500] workSans text-[#7C067D]"
          >Cancel
          </button>
        </div>
      </form>
    </div>
  )
}

export default CreateCourses;
