import React from "react";
import { Route, Routes } from "react-router-dom";
import UserRoutes from './protectedRoutes/UserRoutes';
import PublicRoutes from './protectedRoutes/PublicRoutes';
import Signin from "./components/auth/Signin";
import Dashboard from "./pages/dashboard/Dashboard";
import LiveSession from "./pages/liveStream/LiveSession";
import GoLive from "./pages/liveStream/GoLive";
import VideoCourses from "./pages/uploadVideo/VideoCourses";
import UploadVideo from "./pages/uploadVideo/UploadVideo";
import VideoDetails from "./pages/uploadVideo/VideoDetails";
import Events from "./pages/events/Events";
import CreateEvent from "./pages/events/CreateEvent";
import EventDetails from "./pages/events/EventDetails";
import EditEvent from "./pages/events/EditEvent";
import Users from "./pages/manageUsers/Users";
import UserDetails from "./pages/manageUsers/UserDetails";
import CreateResources from "./pages/manageResources/CreateResources";
import Resources from "./pages/manageResources/Resources";
import ManageSchool from "./pages/manageResources/ManageSchool";
import ManageCourse from "./pages/manageResources/ManageCourse";
import CreateCourses from "./pages/manageResources/CreateCourses";
import EditCourse from "./pages/manageResources/EditCourse";
import ManageModule from "./pages/manageResources/ManageModule";
import CreateModule from "./pages/manageResources/CreateModule";
import EditModule from "./pages/manageResources/EditModule";
import CreateModuleContent from "./pages/manageResources/CreateModuleContent";
import ManageMerch from "./pages/shop/ManageMerch";
import CreateMerch from "./pages/shop/CreateMerch";
import MerchDetails from "./pages/shop/MerchDetails";
import EditMerch from "./pages/shop/EditMerch";
import Orders from "./pages/shop/Orders";
import OrderDetails from "./pages/shop/OrderDetails";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route path="/" element={<Signin />} />
        </Route>
      </Routes>

      {/* Users routes */}
      <Routes>
        <Route element={<UserRoutes />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/live_session" element={<LiveSession />} />
          <Route path="/live_session/:callID" element={<GoLive />} />
          <Route path="/video_courses" element={<VideoCourses />} />
          <Route path="/video_courses/upload_video" element={<UploadVideo />} />
          <Route path="/video_courses/:videoId" element={<VideoDetails />} />
          <Route path="/events" element={<Events />} />
          <Route path="/events/create_video" element={<CreateEvent />} />
          <Route path="/events/edit/:eventId" element={<EditEvent />} />
          <Route path="/events/:eventId" element={<EventDetails />} />
          <Route path="/manage_users" element={<Users />} />
          <Route path="/manage_users/:userId" element={<UserDetails />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/resources/create_course" element={<CreateResources />} />
          <Route path="/resources/school/:resourceId" element={<ManageSchool />} />
          <Route path="/resources/school/:resourceId/course/:courseId" element={<ManageCourse />} />
          <Route path="/create_course/:resourceId" element={<CreateCourses />} />
          <Route path="/edit_course/:resourceId/:courseId" element={<EditCourse />} />
          <Route path="/resources/school/:resourceId/course/:courseId/module/:moduleId" element={<ManageModule />} />
          <Route path="/create_module/:courseId" element={<CreateModule />} />
          <Route path="/edit_module/resource/:resourceId/course/:courseId/module/:moduleId" element={<EditModule />} />
          <Route path="/create_moduleContent/:moduleId" element={<CreateModuleContent />} />
          <Route path="/merch" element={<ManageMerch />} />
          <Route path="/merch/create" element={<CreateMerch />} />
          <Route path="/merch/:merchId" element={<MerchDetails />} />
          <Route path="/merch/edit/:merchId" element={<EditMerch />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/orders/:orderId" element={<OrderDetails />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
