import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logowhite from "./images/logowhite2.png";
import { useNavigate } from 'react-router-dom';
import { MdOutlineDashboard } from "react-icons/md";
import { MdOutlineShoppingCart } from "react-icons/md";
import { LuShoppingBag } from "react-icons/lu";
import { MdLiveTv } from "react-icons/md";
import { BiSolidVideos } from "react-icons/bi";
import { MdOutlineLibraryBooks } from "react-icons/md";
import { LuCalendarDays } from "react-icons/lu";
import { PiUsersThree } from "react-icons/pi";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const tabNum = sessionStorage.getItem("tabNum");
  const [activeIndex, setActiveIndex] = useState(parseFloat(tabNum) || 0);
  const navigate = useNavigate();
  const menuItems = [
    { name: 'Dashboard', icon: <MdOutlineDashboard />, link: '/' },
    { name: 'Live session', icon: <MdLiveTv />, link: '/live_session' },
    { name: 'Upload Video courses', icon: <BiSolidVideos />, link: '/video_courses' },
    { name: 'Course management', icon: <MdOutlineLibraryBooks />, link: '/resources' },
    { name: 'Upcoming events', icon: <LuCalendarDays />, link: '/events' },
    { name: 'User management', icon: <PiUsersThree />, link: '/manage_users' },
  ];

  const merchItems = [
    { name: 'Merch', icon: <MdOutlineShoppingCart />, link: '/merch' },
    { name: 'Orders', icon: <LuShoppingBag />, link: '/orders' }
  ]

  const handleActiveLink = (num) => {
    sessionStorage.setItem("tabNum", num);
    setActiveIndex(num);
    toggleSidebar();
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('streamToken');
    localStorage.removeItem('viewedVideos');
    window.location.reload();
    navigate('/auth/signin');
  }

  const handleLogout = () => {
    if (window.confirm('Are you sure you want to logout?')) {
      logout();
    }
  };

  return (
    <div className={`h-screen z-50 overflow-y-auto w-[13.8rem] fixed max-w-[13.8rem] top-0 left-0 sidebar_bg text-white transition-transform transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 md:flex flex-col justify-between p-4 sidebar_scrollbar`}>
      <div>
        <div className="flex items-center mb-12 mt-2">
          <img src={logowhite} alt="Logo" className="py-[10%] w-[150px] m-auto" />
        </div>
        <div>
          <ul className="">
            {menuItems.map((item, index) => (
              <Link key={index} to={item.link}>
                <li
                  className={`flex items-center px-[16px] py-2 text-[15px] font-[500] workSans mb-4 rounded-[8px] cursor-pointer ${activeIndex === index ? 'bg-white text-[#590064]' : 'hover:bg-purple-700'}`}
                  onClick={() => handleActiveLink(index)}
                >
                  <span className="mr-2 text-[20px]">{item.icon}</span>
                  {item.name}
                </li>
              </Link>
            ))}
          </ul>
          <p className="text-[16px] py-4 font-[500] workSans px-[16px]">SHOP</p>
          <ul className="">
            {merchItems.map((item, index) => (
              <Link key={index} to={item.link}>
                <li
                  className={`flex items-center px-[16px] py-2 text-[15px] font-[500] workSans mb-4 rounded-[8px] cursor-pointer ${activeIndex === index + menuItems.length ? 'bg-white text-[#590064]' : 'hover:bg-purple-700'}`}
                  onClick={() => handleActiveLink(index + menuItems.length)}
                >
                  <span className="mr-2 text-[20px]">{item.icon}</span>
                  {item.name}
                </li>
              </Link>
            ))}
          </ul>
          <li
            className={`flex items-center w-screen px-[16px] py-2 text-[15px] font-[500] workSans mb-4 rounded-[8px] cursor-pointer`}
          >
            <button type="button" onClick={handleLogout}>Logout</button>
          </li>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

