import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchVideos } from '../../redux/videoCourses/getVideos';
import { Link } from 'react-router-dom';

const VideoCourses = () => {
  const { videos, loadingVideos, videosError } = useSelector((state) => state.getVideos);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredVideos = videos.filter((video) => video.videotitle.toLowerCase().includes(searchTerm.toLowerCase()));

  useEffect(() => {
    dispatch(fetchVideos());
  }, [dispatch])

  const limitWords = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  return (
    <div className="p-[4%] min-h-screen">
      <h1 className="text-[28px] workSans font-[700] text-[#121212] mb-6">Video courses</h1>
      <Link to="/video_courses/upload_video">
        <div className="bg-[#59006480] rounded-[16px] p-[16px] mb-6">
          <div className="py-6">
            <div className="flex justify-center items-center">
              <button className="bg-[#590064] hover:bg-[#5800649b] text-white font-bold py-[4px] px-4 rounded-[12px]">
                <span className="text-[24px] font-[700] workSans">+</span>
              </button>
            </div>
            <p className="text-center text-[20px] font-[700] workSans text-white">Upload a video</p>
          </div>
        </div>
      </Link>

      <div className="relative mb-6">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-full md:w-[500px] px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[16px] focus:outline-none focus:ring-2 focus:ring-[#792679] text-[#6D6662] text-[16px] font-[400] workSans"
          placeholder="Search event"
        />
      </div>
      {loadingVideos && <p className="text-[#616161] text-[16px] py-4 font-[500] workSans">Loading videos...</p>}
      {videosError && <p className="text-[#616161] text-[16px] py-4 font-[500] workSans">Error loading videos: {videosError}</p>}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {filteredVideos && filteredVideos.length > 0 ? (
          filteredVideos.map((video) => (
            <div key={video._id} className="bg-[#E20BE308] rounded-[32px] shadow-md p-[24px]">
              <video
                className="w-full h-[250px] z-50 custom-video"
                controls
              >
                <source src={video.videourl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <Link to={`/video_courses/${video._id}`}>
                <div className="cursor-pointer">
                  <p className="font-[600] text-[32px] workSans pt-5 text-[#011114]">{video.videotitle}</p>
                  <p className="text-[#616161] text-[14px] font-[400] workSans">Uploaded {formatDate(video.createdAt)}</p>
                  <div className="flex flex-wrap items-center gap-4 py-5">
                    <p className="text-[rgb(97,97,97)] workSans text-[16px] font-[500]">{video.difficulty}</p>
                    <p className="text-[#616161] workSans text-[16px] font-[500]">|</p>
                    <p className="text-[#616161] workSans text-[16px] font-[500]">{video.duration}</p>
                    <p className="text-[#616161] workSans text-[16px] font-[500]">|</p>
                    <p className="text-[#616161] workSans text-[16px] font-[500]">{video.views} views</p>
                    <p className="text-[#616161] workSans text-[12px] bg-[#E20BE333] rounded-[8px] px-[8px] py-[2px] font-[500]">{video.category}</p>
                  </div>
                  <div className="">
                    <p className="text-[#011114] font-[400] workSans text-[16px]">
                      {limitWords(video.videodescription, 25)}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          ))
        ) : (
          <div className="text-[#616161] text-[16px] font-[500] workSans">{loadingVideos ? "" : "No videos found"}</div>
        )}
      </div>
    </div>
  )
};
export default VideoCourses;
