import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FiUpload } from "react-icons/fi";
import { storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { postMerch } from '../../redux/merch/createMerch';
import { FaTrash } from "react-icons/fa";

const CreateMerch = () => {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    productname: '',
    price: 0,
    quantity: 0,
    productSizes: [{ size: '', quantity: "" }],
    productimage: ''
  });
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const maxSizeInKB = 500; // 500 KB

    if (selectedFile) {
      if (selectedFile.type.startsWith('image/')) {
        const fileSizeInKB = selectedFile.size / 1024;
        if (fileSizeInKB > maxSizeInKB) {
          alert('File size exceeds 500KB. Please select a smaller file.');
          return;
        }

        setFile(selectedFile);
        const imagePreviewUrl = URL.createObjectURL(selectedFile);
        setImagePreviewUrl(imagePreviewUrl);
      } else {
        alert('Please select a valid image file.');
      }
    }
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a merch image to upload.');
      return;
    }

    const storageRef = ref(storage, `merchImages/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file, {
      contentType: file.type
    });

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error('Upload failed', error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFormData((prevData) => ({ ...prevData, productimage: downloadURL }));
          console.log("File available at", downloadURL);
        });
      }
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSizeChange = (index, e) => {
    const { name, value } = e.target;
    const updatedSizes = formData.productSizes.map((sizeObj, i) => (
      i === index ? { ...sizeObj, [name]: value } : sizeObj
    ));
    setFormData({ ...formData, productSizes: updatedSizes });
  };

  const addVariation = () => {
    setFormData({
      ...formData,
      productSizes: [...formData.productSizes, { size: '', quantity: 0 }]
    });
  };

  const removeVariation = (index) => {
    setFormData({
      ...formData,
      productSizes: formData.productSizes.filter((_, i) => i !== index)
    });
  };

  const createMerch = async () => {
    setLoading(true);
    const data = {
      productname: formData.productname,
      quantity: formData.quantity,
      productSizes: formData.productSizes,
      price: formData.price,
      productimage: formData.productimage,
    };

    dispatch(postMerch(data)).then((res) => {
      if (res.error) {
        alert("error creating merch")
        setLoading(false);
      } else {
        alert("Merch created successfully");
        setLoading(false);
        setTimeout(() => {
          navigate("/merch");
          window.location.reload();
        }, 1000)
      }
    })
  };

  const areFieldsEmpty = () => {
    return !formData.productname || !formData.productSizes
      || !formData.price || !formData.productimage
  };

  return (
    <div className="p-[4%] w-full">
      <h2 className="text-[#121212] text-[28px] font-[700] workSans mb-6">Create a merchandise</h2>
      <div className="grid grid-cols-2 gap-6 mb-4">
        <div>
          <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="productName">Product Name</label>
          <input
            type="text"
            name="productname"
            placeholder="product name"
            className="w-full px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[8px] focus:outline-none focus:ring-2 focus:ring-indigo-600 text-[#BFBBBB] text-[16px] font-[400] workSans"
            value={formData.productname}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="price">Price</label>
          <input
            type="number"
            name="price"
            placeholder="price"
            className="w-full px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[8px] focus:outline-none focus:ring-2 focus:ring-indigo-600 text-[#BFBBBB] text-[16px] font-[400] workSans"
            value={formData.price}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="quantity">Quantity</label>
          <input
            type="number"
            name="quantity"
            placeholder="Quantity"
            className="w-full px-[16px] py-[10px] border-[1px] border-[#D9D9D9] rounded-[8px] focus:outline-none focus:ring-2 focus:ring-indigo-600 text-[#BFBBBB] text-[16px] font-[400] workSans"
            value={formData.quantity}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="productSizes">Product Variations</label>
          {formData.productSizes.map((sizeObj, index) => (
            <div key={index} className="flex space-x-2">
              <div>
                <label className="block text-[#616161] text-[14px] font-[600] workSans" htmlFor="variation">Variation</label>
                <input
                  type="text"
                  name="size"
                  placeholder="eg XXL, EU 40-41"
                  className="w-full lg:w-full px-[16px] py-[3px] border-[1px] border-[#D9D9D9] rounded-[8px] focus:outline-none focus:ring-2 focus:ring-indigo-600 text-[#BFBBBB] text-[16px] font-[400] workSans"
                  value={sizeObj.size}
                  onChange={(e) => handleSizeChange(index, e)}
                />
              </div>
              <div>
                <label className="block text-[#616161] text-[14px] font-[600] workSans" htmlFor="productQuantity">Quantity</label>
                <input
                  type="number"
                  name="quantity"
                  placeholder="0"
                  className="w-full lg:w-1/2 px-[16px] py-[3px] border-[1px] border-[#D9D9D9] rounded-[8px] focus:outline-none focus:ring-2 focus:ring-indigo-600 text-[#BFBBBB] text-[16px] font-[400] workSans"
                  value={sizeObj.quantity}
                  onChange={(e) => handleSizeChange(index, e)}
                  min="0"
                />
              </div>
              <button
                type="button"
                className="text-red-500 text-[14px] font-[600] workSans"
                onClick={() => removeVariation(index)}
              >
                <FaTrash />
              </button>
            </div>
          ))}
          <button
            type="button"
            className="linear_gradient_btn text-white mt-1 text-[14px] workSans font-[400] px-2 py-[2px] rounded-md"
            onClick={addVariation}
          >
            Add Variation
          </button>
        </div>
      </div>
      <div className="mb-6">
        <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2">Upload Merch image</label>
        <div className="bg-[#E20BE308] rounded-[8px] p-16 text-center">
          <input type="file" className="hidden" id="uploadImage" onChange={handleFileChange} />
          <label
            htmlFor="uploadImage"
            className="cursor-pointer text-[#E20BE3] flex flex-col items-center"
          >
            <FiUpload />
            <span className="font-[400] text-[16px] workSans">Upload image</span>
          </label>
        </div>

        {imagePreviewUrl && (
          <div className="mt-4">
            <img
              alt="eventImage preview"
              src={imagePreviewUrl}
              className="w-full h-[250px] rounded-[8px] mt-4"
            />
          </div>
        )}

        <button
          className="linear_gradient_btn text-white mt-1 text-[14px] workSans font-[400] px-2 py-[2px] rounded-md"
          onClick={handleUpload}
        >
          Upload image
        </button>
        <p className="text-[14px] workSans font-[400]">Upload Progress: {progress}%</p>
      </div>
      <div className="flex justify-center">
        <button
          onClick={createMerch}
          className={`linear_gradient_btn text-white font-[600] text-[14px] workSans py-[18px] px-[90px] ${areFieldsEmpty() ? 'opacity-50 cursor-not-allowed' : ''} rounded-[32px]`}
          disabled={areFieldsEmpty()}
        >
          {loading ? "Loading..." : "Create Product"}
        </button>
      </div>
    </div>
  );
}

export default CreateMerch;
