import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEvents } from '../../redux/event/events';
import {
  StreamCall, StreamVideo, StreamVideoClient,
  useCall, useCallStateHooks, ParticipantView,
} from "@stream-io/video-react-sdk";
import '@stream-io/video-react-sdk/dist/css/styles.css';
import { Chat, Channel, ChannelHeader, MessageList, MessageInput, Window } from 'stream-chat-react';
import { StreamChat } from 'stream-chat';
// import '@stream-io/stream-chat-css/dist/css/index.css';
import 'stream-chat-react/dist/css/v2/index.css';
import { useRef, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useParams, useNavigate } from 'react-router-dom';

const apiKey = "9xwu9sct59s7";
const token = localStorage.getItem("streamToken");

const GoLive = () => {
  const { callID } = useParams();
  const navigate = useNavigate();
  const { events } = useSelector((state) => state.getEvents);
  const dispatch = useDispatch();

  // State to hold client, call, chatClient, and channel
  const [client, setClient] = useState(null);
  const [call, setCall] = useState(null);
  const [chatClient, setChatClient] = useState(null);
  const [channel, setChannel] = useState(null);

  useEffect(() => {
    dispatch(fetchEvents());
  }, [dispatch]);

  useEffect(() => {
    if (events?.length > 0) {
      if (!callID || !events.some(event => event._id === callID)) {
        navigate("/live_session");
      }
    }
  }, [callID, events, navigate]);

  useEffect(() => {
    const initializeStream = async () => {
      if (!token) {
        console.error("No token found in local storage");
        return <div>No token found. Please log in.</div>;
      }
      const decodedToken = jwtDecode(token);
      const userId = decodedToken?.user_id;
      const callId = callID;

      const user = {
        id: userId,
        // role: "admin",
        name: "Thewavetribe",
        image: "https://getstream.io/random_svg/?id=thewavetribe&name=thewavetribe"
      };
      try {
        const clientInstance = new StreamVideoClient({ apiKey: apiKey, user: user, token: token });
        setClient(clientInstance);

        const callInstance = clientInstance.call('livestream', callId);
        await callInstance.join({ create: true, audio: true });
        setCall(callInstance);

        const chatClientInstance = StreamChat.getInstance(apiKey);
        await chatClientInstance.connectUser(user, token);
        setChatClient(chatClientInstance);

        const channelInstance = chatClientInstance.channel('livestream', callId, {
          name: 'Livestream Chat',
          members: [userId],
        });

        await channelInstance.watch();
        setChannel(channelInstance);

      } catch (error) {
        console.error("Error initializing stream:", error);
      }
    };

    initializeStream();
  }, [callID, token]);

  if (!client || !call || !chatClient || !channel) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-[4%]">
      <div className="md:flex items-start justify-between gap-4">
        <StreamVideo client={client}>
          <StreamCall call={call}>
            <MyLiveStreamUI />
          </StreamCall>
        </StreamVideo>
        <Chat client={chatClient} theme="livestream dark">
          <Channel channel={channel}>
            <Window>
              <ChannelHeader />
              <MessageList />
              <MessageInput />
            </Window>
          </Channel>
        </Chat>
      </div>
    </div>
  );
};

export const MyLiveStreamUI = () => {
  const call = useCall();
  const { useIsCallLive, useLocalParticipant, useParticipantCount,
    useScreenShareState } = useCallStateHooks();
  const totalParticipants = useParticipantCount();
  const localParticipant = useLocalParticipant();
  const isCallLive = useIsCallLive();
  const { screenShare, status } = useScreenShareState();

  const handleScreenShare = async () => {
    await screenShare.toggle();
  }

  const [recording, setRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const mediaRecorderRef = useRef(null);
  const mediaStreamRef = useRef(null);

  const startRecording = async () => {
    try {
      // Get the screen stream
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: true, // Captures system audio if available
      });

      // Get the microphone audio stream
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true, // Captures microphone audio
      });

      // Combine the screen and microphone audio streams
      const combinedStream = new MediaStream([
        ...screenStream.getVideoTracks(),
        ...screenStream.getAudioTracks(),
        ...audioStream.getAudioTracks(),
      ]);

      mediaStreamRef.current = combinedStream;
      mediaRecorderRef.current = new MediaRecorder(combinedStream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          setRecordedChunks((prev) => [...prev, event.data]);
        }
      };

      mediaRecorderRef.current.start();
      setRecording(true);
    } catch (err) {
      console.error("Error starting recording:", err);
    }
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    mediaStreamRef.current.getTracks().forEach(track => track.stop());
    setRecording(false);
  };

  const saveRecording = () => {
    const blob = new Blob(recordedChunks, { type: 'video/webm' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'screen_recording.webm';
    a.click();
    URL.revokeObjectURL(url);
    setRecordedChunks([]);
  };

  return (
    <div>
      <div>
        live: {totalParticipants}
      </div>
      <div>
        {localParticipant && (
          <ParticipantView
            participant={localParticipant}
            ParticipantViewUI={null}
          />
        )}
      </div>
      <div className="flex flex-wrap gap-4 items-center">
        {isCallLive ? (
          <button
            type="button"
            className="bg-red-500 text-white font-[600] my-4 text-[14px] workSans py-[18px] px-[90px] rounded-[32px]"
            onClick={() => call?.stopLive()}>
            Stop Livestream
          </button>
        ) : (
          <button
            type="button"
            onClick={() => call?.goLive()}
            className="border-[2px] py-[11px] px-[35px] border-[#7C067D] my-4 rounded-[32px] text-[14px] font-[500] workSans text-[#7C067D]"
          >
            Start Livestream
          </button>
        )}
        <button
          type="button"
          onClick={handleScreenShare}
          className="linear_gradient_btn text-white font-[600] text-[14px] workSans py-[18px] px-[90px] rounded-[32px]"
        >
          {status === 'enabled' ? 'stop sharing' : 'share'}
        </button>
        <div>
          <button
            onClick={recording ? stopRecording : startRecording}
            className="border-[2px] py-[11px] px-[35px] border-[#7C067D] my-4 rounded-[32px] text-[14px] font-[500] workSans text-[#7C067D]"
          >
            {recording ? "Stop Recording" : "Start Recording"}
          </button>
          {recordedChunks.length > 0 && (
            <button
              onClick={saveRecording}
              className="linear_gradient_btn text-white font-[600] text-[14px] workSans py-[18px] px-[90px] rounded-[32px]"
            >
              Save Recording
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default GoLive;
